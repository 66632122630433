import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConnections, createConnection, updateConnection, deleteConnection } from '../../../../redux/slices/connectionSlice';
import { fetchWorkers } from '../../../../redux/slices/workersSlice';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownConnections from '../../../../components/common/ActionDropdownConnections';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';
import { Connection, Worker } from 'components/common/types'; // Ensure this path is correct
// import axios from 'axios';
import api from 'services/api';

const ListDatabaseConnections = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const connections = useSelector((state: any) => state.connections.items);
    const workers = useSelector((state: any) => state.workers.items); // Fetch workers from the Redux store
    const loading = useSelector((state: any) => state.connections.loading);

    const [showModal, setShowModal] = useState(false);
    const [currentConnection, setCurrentConnection] = useState<Connection | null>(null);
    const [databaseTypes, setDatabaseTypes] = useState<string[]>([]);
    const [connectionData, setConnectionData] = useState<Omit<Connection, 'connection_id'>>({
        connection_name: '',
        host: '',
        port: 0,
        database: '',
        user: '',
        password: '',
        database_type: '',
        workers: [],
    });

    useEffect(() => {
        dispatch(fetchConnections());
        dispatch(fetchWorkers());
        fetchDatabaseTypes();
    }, [dispatch]);

    const fetchDatabaseTypes = async () => {
        try {
            const response = await api.get('/list_of_values/key/database_type');
            setDatabaseTypes(response.data);
        } catch (error) {
            console.error("Failed to fetch database types", error);
        }
    };

    const handleAction = async (connection_id: number, action: string) => {
        const connection = connections.find((connection: Connection) => connection.connection_id === connection_id);
        if (action === 'remove') {
            dispatch(deleteConnection(connection_id));
        } else if (action === 'edit') {
            setCurrentConnection(connection);
            if (connection) {
                setConnectionData({ ...connection, port: connection.port });
            } else {
                setConnectionData({
                    connection_name: '',
                    host: '',
                    port: 0,
                    database: '',
                    user: '',
                    password: '',
                    database_type: '',
                    workers: [],
                });
            }
            setShowModal(true);
        }
    };

    const columns: ColumnDef<Connection>[] = [
        {
            accessorKey: 'connection_name',
            header: 'Connection Name',
            cell: ({ row }) => (
                <span
                    onClick={() => handleAction(row.original.connection_id, 'view')}
                    style={{ cursor: 'pointer', color: '#007bff' }}
                >
                    {row.original.connection_name}
                </span>
            ),
        },
        {
            id: 'action',
            cell: ({ row }) => (
                <RevealDropdownTrigger>
                    <RevealDropdown>
                        <ActionDropdownConnections connection_id={row.original.connection_id} onAction={handleAction} />
                    </RevealDropdown>
                </RevealDropdownTrigger>
            ),
            meta: {
                headerProps: { style: { width: '7%' } },
                cellProps: { className: 'text-end' },
            },
        },
    ];

    const table = useAdvanceTable({
        data: connections,
        columns,
        pageSize: 20,
        pagination: true,
        selection: true,
        sortable: true,
    });

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        table.setGlobalFilter(e.target.value || undefined);
    };

    const handleNewConnectionClick = () => {
        setCurrentConnection(null);
        setConnectionData({
            connection_name: '',
            host: '',
            port: 0,
            database: '',
            user: '',
            password: '',
            database_type: '',
            workers: [],
        });
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleSave = () => {
        const updatedData = { ...connectionData, port: parseInt(connectionData.port.toString(), 10) };
        if (currentConnection) {
            dispatch(updateConnection({ ...currentConnection, ...updatedData }));
        } else {
            dispatch(createConnection(updatedData));
        }
        handleCloseModal();
    };

    return (
        <Container fluid className="p-0">
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                    <SearchBox
                        placeholder="Search..."
                        size="sm"
                        onChange={handleSearchInputChange}
                        className="mx-auto"
                    />
                    <Button variant="primary" size="sm" onClick={handleNewConnectionClick}>
                        New Connection
                    </Button>
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="p-0">
                    <AdvanceTableProvider {...table}>
                        <AdvanceTable
                            tableProps={{
                                size: 'sm',
                                className: 'phoenix-table fs-9 mb-0 border-top border-translucent w-100',
                            }}
                            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
                        />
                        <AdvanceTableFooter navBtn />
                    </AdvanceTableProvider>
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentConnection ? 'Edit Connection' : 'New Connection'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="connectionName">
                            <Form.Label>Connection Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.connection_name}
                                onChange={(e) => setConnectionData({ ...connectionData, connection_name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="host">
                            <Form.Label>Host</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.host}
                                onChange={(e) => setConnectionData({ ...connectionData, host: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="port">
                            <Form.Label>Port</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.port.toString()}
                                onChange={(e) => setConnectionData({ ...connectionData, port: parseInt(e.target.value, 10) })}
                            />
                        </Form.Group>
                        <Form.Group controlId="database">
                            <Form.Label>Database</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.database}
                                onChange={(e) => setConnectionData({ ...connectionData, database: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="user">
                            <Form.Label>User</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.user}
                                onChange={(e) => setConnectionData({ ...connectionData, user: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={connectionData.password}
                                onChange={(e) => setConnectionData({ ...connectionData, password: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="databaseType">
                            <Form.Label>Database Type</Form.Label>
                            <Form.Control
                                as="select"
                                value={connectionData.database_type}
                                onChange={(e) => setConnectionData({ ...connectionData, database_type: e.target.value })}
                            >
                                <option value="">Select Database Type</option>
                                {databaseTypes.map((type) => (
                                    <option key={type} value={type}>
                                        {type}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="workers">
                            <Form.Label>Workers</Form.Label>
                            {workers.map((worker: Worker) => (
                                <div key={worker.worker_id} className="mb-2">
                                    <Form.Check
                                        type="checkbox"
                                        label={worker.worker_name}
                                        checked={connectionData.workers?.some((w) => w.worker_id === worker.worker_id) || false}
                                        onChange={(e) => {
                                            const updatedWorkers = e.target.checked
                                                ? [...(connectionData.workers || []), { ...worker, priority: 0 }]
                                                : (connectionData.workers || []).filter((w) => w.worker_id !== worker.worker_id);
                                            setConnectionData({ ...connectionData, workers: updatedWorkers });
                                        }}
                                    />
                                    {connectionData.workers?.some((w) => w.worker_id === worker.worker_id) && (
                                        <Form.Control
                                            type="number"
                                            value={
                                                connectionData.workers.find((w) => w.worker_id === worker.worker_id)?.priority
                                            }
                                            onChange={(e) => {
                                                const updatedWorkers = (connectionData.workers || []).map((w) =>
                                                    w.worker_id === worker.worker_id
                                                        ? { ...w, priority: parseInt(e.target.value, 10) }
                                                        : w
                                                );
                                                setConnectionData({ ...connectionData, workers: updatedWorkers });
                                            }}
                                            placeholder="Priority"
                                        />
                                    )}
                                </div>
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ListDatabaseConnections;
