import { createSlice } from '@reduxjs/toolkit'
import { fetchContracts, fetchContractLovs, updateContract, deleteContract } from './configThunk';

const initialState = {
    contracts: [],
    contractLovs: {},
    contracts_loading: false,
    fetchContractLovs_loading: false
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            // ###### FETCH CONTRACTS
            .addCase(fetchContracts.pending, (state, action) => {
                state.contracts_loading = true;
            })
            .addCase(fetchContracts.fulfilled, (state, action) => {
                const contracts = action.payload;
                state.contracts = contracts;
                // state.userData = user;
                state.contracts_loading = false;
            })
            .addCase(fetchContracts.rejected, (state, action) => {
                state.contracts_loading = false;
                state.contracts = [];
            })


            // ###### FETCH CONTRACT LIST OF VALUES
            .addCase(fetchContractLovs.pending, (state, action) => {
                state.fetchContractLovs_loading = true;
            })
            .addCase(fetchContractLovs.fulfilled, (state, action) => {
                const contractLovs = action.payload;
                state.contractLovs = contractLovs;
                // state.userData = user;
                state.fetchContractLovs_loading = false;
            })
            .addCase(fetchContractLovs.rejected, (state, action) => {
                state.fetchContractLovs_loading = false;
                state.contracts = [];
            })





            // ###### UPDATE CONTRACT
            .addCase(updateContract.pending, (state, action) => {
                state.updateContractLoading = true;
            })
            .addCase(updateContract.fulfilled, (state, action) => {
                // const {token} = action.payload;
                // state.token = token;
                // state.userData = user;
                const index = state.contracts.findIndex((e) => e.contract_id === action.payload.contract_id)
                state.contracts[index] = action.payload
                state.updateContractLoading = false;
                // state.listeners[action.stateId] = action.payload;
            })
            .addCase(updateContract.rejected, (state, action) => {
                state.updateContractLoading = false;
            })

            // ###### DELETE CONTRACT
            .addCase(deleteContract.pending, (state, action) => {
                state.deleteContractLoading = true;
                // state.listeners[action.stateId] = {};
                // state.token = null;
            })
            .addCase(deleteContract.fulfilled, (state, action) => {
                // const {token} = action.payload;
                // state.token = token;
                // state.userData = user;
                // const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
                state.contracts = state.contracts.filter(contract => action.payload.contract_id !== contract.contract_id)
                // users: state.users.filter(user => action.payload !== user.user_id)
                state.deleteContractLoading = false;
                // state.listeners[action.stateId] = action.payload;
            })
            .addCase(deleteContract.rejected, (state, action) => {
                state.deleteContractLoading = false;
            })


    },
})


export const { } = configSlice.actions;

export default configSlice.reducer;