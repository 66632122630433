import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchBlocks, createBlock, updateBlock, deleteBlock } from '../slices/blocksSlice';
import api from '../../services/api';

function* handleFetchBlocks(action) {
    try {
        const response = yield call(api.get, '/blocks');
        yield put({
            type: fetchBlocks.fulfilled.type,
            payload: response.data,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'fulfilled'
            }
        });
    } catch (error) {
        yield put({
            type: fetchBlocks.rejected.type,
            error: error.message,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'rejected'
            }
        });
    }
}

function* handleCreateBlock(action) {
    try {
        const response = yield call(api.post, '/blocks', action.payload);
        yield put({
            type: createBlock.fulfilled.type,
            payload: response.data,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'fulfilled'
            }
        });
    } catch (error) {
        yield put({
            type: createBlock.rejected.type,
            error: error.message,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'rejected'
            }
        });
    }
}

function* handleUpdateBlock(action) {
    try {
        const response = yield call(api.put, `/blocks/${action.payload.block_id}`, action.payload);
        yield put({
            type: updateBlock.fulfilled.type,
            payload: response.data,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'fulfilled'
            }
        });
    } catch (error) {
        yield put({
            type: updateBlock.rejected.type,
            error: error.message,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'rejected'
            }
        });
    }
}

function* handleDeleteBlock(action) {
    try {
        yield call(api.delete, `/blocks/${action.payload}`);
        yield put({
            type: deleteBlock.fulfilled.type,
            payload: action.payload,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'fulfilled'
            }
        });
    } catch (error) {
        yield put({
            type: deleteBlock.rejected.type,
            error: error.message,
            meta: {
                arg: action.meta.arg,
                requestId: action.meta.requestId,
                requestStatus: 'rejected'
            }
        });
    }
}

function* watchBlocksActions() {
    yield takeEvery(fetchBlocks.pending.type, handleFetchBlocks);
    yield takeEvery(createBlock.pending.type, handleCreateBlock);
    yield takeEvery(updateBlock.pending.type, handleUpdateBlock);
    yield takeEvery(deleteBlock.pending.type, handleDeleteBlock);
}

export default watchBlocksActions;
