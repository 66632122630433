//CustomNode.jsx
import React from 'react';
import PropTypes from 'prop-types';
import { Handle, Position } from 'reactflow';
import { NodeProps, Node } from 'reactflow';


const StandardNode = ({ data, type, id }) => {
  // console.log("Node type: " + type)
  // console.log("Node status: " + data.status)
  const nodeClass = `dndnode status-${data.status || 'unknown'} type-${type || 'unknown'}`;

  return (
    <div className={nodeClass}>
      <div>{data.label}</div>
      <div style={{ fontSize: '0.75em'}}>ID: {id}</div>
      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={Position.Right} />
    </div>
  );
};


export default StandardNode;