import React, { Fragment, useEffect, useState } from "react";
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';

// import { ChangeEvent } from 'react';
// import SearchBox from 'components/common/SearchBox';

import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";

// import EditListener from "./editListener";
// import CreateListener from "./createListener";

import { fetchUserfolders } from "../../../../redux/slices/userfoldersThunk";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const ListUserfolders = () => {

    const dispatch = useDispatch();
    const userfolders = useSelector((state: RootStateOrAny) => state.userfolders.userfolders)
    const userfoldersLoading = useSelector((state: RootStateOrAny) => state.userfolders.loading)

    useEffect(() => {
        // getFiles();
        dispatch(fetchUserfolders());
    }, []);







    return (
        <div>
            <h1>Userfolders</h1>

            <Table hover size='sm' >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>User ID</th>
                        <th>path</th>
                        <th>home folder</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(userfolders).map(([key, userfolder], i) => (
                        <tr key={i}>
                            <td>{userfolders[i].userfolder_id}</td>
                            <td>{userfolders[i].user_id}</td>
                            <td>{userfolders[i].path}</td>
                            <td>{JSON.stringify(userfolders[i].home_folder)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>




        </div>

    )
};





export default ListUserfolders;