import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootStateOrAny } from 'react-redux';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { listTotalEnergy } from "../../../../redux/slices/influxThunk";

Chart.register(...registerables);

interface InfluxData {
    result: string;
    table: number;
    _start: string;
    _stop: string;
    _time: string;
    _value: number;
    _field: string;
    _measurement: string;
    domain: string;
    entity_id: string;
    friendly_name: string;
    source: string;
}

const InfluxGraph: React.FC = () => {
    const dispatch = useDispatch();
    const influx = useSelector((state: RootStateOrAny) => state.influx.influx);
    const influxLoading = useSelector((state: RootStateOrAny) => state.influx.loading);

    useEffect(() => {
        const body = { entities: ["power_consumption", "power_production"], "start": "2024-06-01T00:00:00Z", "end": "2024-06-03T00:00:00Z" };
        dispatch(listTotalEnergy(body));
    }, [dispatch]);

    if (influxLoading) {
        return <div>Loading...</div>;
    }

    // Format and sort the data to be compatible with Chart.js
    const formattedData = influx.map((item: InfluxData) => ({
        time: new Date(item._time).toISOString(),
        value: item._value,
        entity_id: item.entity_id
    })).sort((a, b) => new Date(a.time).getTime() - new Date(b.time).getTime());

    // Group data by entity_id
    const dataByEntityId = formattedData.reduce((acc: Record<string, any[]>, item) => {
        if (!acc[item.entity_id]) {
            acc[item.entity_id] = [];
        }
        acc[item.entity_id].push(item);
        return acc;
    }, {});

    // Prepare datasets for Chart.js
    const datasets = Object.keys(dataByEntityId).map(entity_id => ({
        label: entity_id,
        data: dataByEntityId[entity_id].map(d => ({ x: d.time, y: d.value })),
        fill: false,
        borderColor: entity_id === 'power_production' ? "#00FF00" : "#FF0000",
        tension: 0.1,
        spanGaps: true // This option ensures lines are drawn between points
    }));

    const chartData = {
        datasets: datasets
    };

    const options = {
        scales: {
            x: {
                type: 'time' as const,
                time: {
                    tooltipFormat: 'dd-MM HH:mm', // Format in tooltip
                    displayFormats: {
                        minute: 'dd-MM HH:mm', // Format on axis
                        hour: 'dd-MM HH:mm', // Format on axis
                        day: 'dd-MM HH:mm' // Format on axis
                    }
                },
                ticks: {
                    autoSkip: true, // Automatically skip ticks to avoid crowding
                    maxTicksLimit: 10 // Maximum number of ticks to show
                },
                title: {
                    display: true,
                    text: 'Time'
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Value (kW)'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top' as const,
            },
            tooltip: {
                mode: 'index' as const,
                intersect: false
            }
        },
        maintainAspectRatio: false
    };

    return (
        <div style={{ width: '100%', height: '400px' }}>
            <Line data={chartData} options={options} />
        </div>
    );
};

export default InfluxGraph;
