import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import { Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

// import PasswordChecklist from "react-password-checklist"


import { connect } from 'react-redux'
import { updateRole } from '../../../../redux/slices/rolesThunk';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';


const EditRole = (props) => {
    const dispatch = useDispatch();
  console.log("props: "+JSON.stringify(props))
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const role_id = useSelector((state: RootStateOrAny) => state.roles.roles[props.stateId].role_id);
//   const status = useSelector((state: RootStateOrAny) => state.listeners.listeners[props.stateId].status);
  const [name, setName] = useState<string>()
//   const [port, setPort] = useState<string>()
//   const [listener_name, setlistener_name] = useState<string>()
//   const [auto_start, setAuto_start] = useState<boolean>()



  const handleUpdateRole = async (e) => {
    
    // console.log("editListener - e: " + JSON.stringify(e))
    try {
        e.preventDefault();
      const body = { role_id, name }
      console.log("editRole - body: " + JSON.stringify(body))
      dispatch(updateRole(body));
    // props.updateUser(body)
    // console.log(body)
    } catch (error) {
    console.error(error.message)
  }
}


return (

  <>
    <Button variant="phoenix-secondary" onClick={handleShow}>
      Edit
    </Button>

    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
      <Modal.Header>
        <Modal.Title>Edit Role</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form.Group as={Row} className="mb-3" controlId="formRoleID">
          <Form.Label column sm="2">
            Role ID
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={role_id} plaintext readOnly />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formRoleName">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={useSelector((state: RootStateOrAny) => state.roles.roles[props.stateId].name)} onChange={ev => setName(ev.target.value)}/>
          </Col>
        </Form.Group>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
          Close
        </Button>
        <Button variant="phoenix-primary" onClick={(e) => handleUpdateRole(e)} startIcon={<FontAwesomeIcon icon={faSave} />}>Save</Button>
      </Modal.Footer>
    </Modal>
  </>
);
}

// export default EditUser;




// const mapStateToProps = (state, ownProps) => {
//   const user_id = ownProps.user_id
//   return {
//     userItem: state.user.users.find(item => item.user_id === user_id)
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     updateUser: user => dispatch(updateUser(user))
//   }
// }


// export default connect(mapStateToProps,mapDispatchToProps)(EditUser)

export default EditRole