import {
    FETCH_USERS_FAILURE,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
} from "./userTypes"

const initialState = {
    loading: false,
    users: [],
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload,
                error: ''
            }
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                users: [],
                error: action.payload
            }
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                users: state.users.map((e) => e.user_id === action.payload.user_id ? { ...e, status: "Change requested" } : e)
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map((e) => e.user_id === action.payload.user_id ? action.payload : e)
            }
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                users: state.users.map((e) => e.user_id === action.payload.user_id ? { ...e, status: "Problem updating" } : e)
            }
        case CREATE_USER_REQUEST:
            return {
                ...state,
                users: [...state.users, { username: action.payload.username, status: "Creation requested" }]
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.map((e) => e.username === action.payload[0].username ? action.payload[0] : e)
            }
        case CREATE_USER_FAILURE:
            return {
                ...state,
                users: state.users.map((e) => e.user_id === action.payload.user_id ? { ...e, status: "Problem updating" } : e)
            }
        case DELETE_USER_REQUEST:
            return {
                ...state,
                users: state.users.map((e) => e.user_id === action.payload ? { ...e, status: "Delete requested" } : e)
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                users: state.users.filter(user => action.payload !== user.user_id)
            }
        case DELETE_USER_FAILURE:
            return {
                ...state,
                users: state.users.map((e) => e.user_id === action.payload ? { ...e, status: "Problem deleting" } : e)
            }
        default: return state
    }
}

export default reducer