import { Link } from 'react-router-dom';
import './SchedulerProject.css'; // Ensure you have a CSS file for custom styles

import { schedulerUrl, projectId } from './tempconfig';
const iframeurl = schedulerUrl+"/projects/"+projectId+"/workflow/instances?projectName=";

const WorkflowInstances = () => {
  return (
    <div className="container-fullscreen">
      <iframe
        src={iframeurl}
        className="iframe-fullscreen"
        title="SchedulerProject"
      ></iframe>
    </div>
  );
};

export default WorkflowInstances;
