import React from 'react';
import { useHass } from '../HassProvider';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOff } from '@fortawesome/free-solid-svg-icons';

const HassEntity: React.FC<{ entityId: string }> = ({ entityId }) => {
  const { entities, callService } = useHass();

  if (!entities) return <div>Loading...</div>;

  const entity = entities[entityId];
  if (!entity) return <div>Entity not found</div>;

  const toggle = () => {
    const domain = entityId.split('.')[0];
    const service = entity.state === 'on' ? 'turn_off' : 'turn_on';

    callService(domain, service, { entity_id: entityId });
  };

  return (
    <tr>
      <td>{entity.attributes.friendly_name}</td>
      <td>{entity.state}</td>
      <td>
      <ButtonGroup aria-label="Basic example">
        <Button variant="phoenix-primary" onClick={toggle} startIcon={<FontAwesomeIcon icon={faToggleOff} />}>
            Switch
        </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

export default HassEntity;
