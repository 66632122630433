import React, { Fragment, useEffect, useState } from "react";



import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';

// import { ChangeEvent } from 'react';
// import SearchBox from 'components/common/SearchBox';

import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";

import EditListener from "./editListener";
import CreateListener from "./createListener";

import { deleteListener, fetchListeners, startListener } from "../../../../redux/slices/listenersThunk";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

const ListListeners = () => {

    const dispatch = useDispatch();
    const listeners = useSelector((state: RootStateOrAny) => state.listeners.listeners)
    const listenersLoading = useSelector((state: RootStateOrAny) => state.listeners.loading)

    useEffect(() => {
        // getFiles();
        dispatch(fetchListeners());
    }, []);


    const handleStartListener = async (listener_id) => {
    
        // console.log("editListener - e: " + JSON.stringify(e))
        try {
            // e.preventDefault();
          const body = { listener_id }
          console.log("listeners handleStartListener - body: " + JSON.stringify(body))
          dispatch(startListener(body));
        // props.updateUser(body)
        // console.log(body)
        } catch (error) {
        console.error(error.message)
      }
    }

    const handleDeleteListener = async (listener_id) => {
        try {
            const body = {listener_id}
            dispatch(deleteListener(body))
        } catch (error) {
            console.error(error.message)
        }
    }
    

    // // const [listeners, setListeners] = useState<any[]>([]);
    // const getListeners = async () => {
    //     try {
    //         const response = await fetch("http://localhost:5002/listeners")
    //         const jsonData = await response.json()
    //         console.log("test log console")
    //         setListeners(jsonData);
    //     } catch (err) {
    //         console.error(JSON.stringify(err));
    //     }
    // };

    // useEffect(() => {
    //     getListeners();
    //     console.log("From useEffect function. Type: " + typeof listeners)
    //     console.log(listeners)
    // }, []);





    return (
        <div>
            <h1>Listeners</h1>

            <Table hover size='sm' >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Protocol</th>
                        <th>Port</th>
                        <th>Name</th>
                        <th>Autostart</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(listeners).map(([key, listener], i) => (
                        <tr key={i}>
                            <td>{listeners[i].listener_id}</td>
                            <td>{listeners[i].protocol}</td>
                            <td>{listeners[i].port}</td>
                            <td>{listeners[i].listener_name}</td>
                            <td>{JSON.stringify(listeners[i].auto_start)}</td>
                            <td>{listeners[i].status}</td>
                            <td>
                            <ButtonGroup aria-label="Basic example">
                            <Button variant="phoenix-success" size="sm" onClick={() => handleStartListener(listeners[i].listener_id)}> Start </Button> 
                            <Button variant="phoenix-warning" size="sm"> Stop </Button> 
                            <EditListener stateId={i} />
                            <Button variant="phoenix-danger" size="sm" onClick={() => handleDeleteListener(listeners[i].listener_id)}> Delete </Button>
                            
                                </ButtonGroup>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </Table>


                        <CreateListener />






        </div>

    )
};





export default ListListeners;