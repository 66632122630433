import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface Worker {
    worker_id: number;
    worker_name: string;
    priority?: number; // Ensure priority is a number
}

interface Connection {
    connection_id: number;
    connection_name: string;
    host: string;
    port: number;
    database: string;
    user: string;
    password: string;
    database_type: string;
    workers?: Worker[];
}

interface ConnectionState {
    items: Connection[];
    status: string | null;
}

const initialState: ConnectionState = {
    items: [],
    status: null,
};

export const fetchConnections = createAsyncThunk('connections/fetchConnections', async () => {
    const response = await api.get('/connections/database');
    return response.data;
});

export const createConnection = createAsyncThunk('connections/createConnection', async (connection: Omit<Connection, 'connection_id'>) => {
    const response = await api.post('/connections/database', connection);
    return response.data;
});

export const updateConnection = createAsyncThunk('connections/updateConnection', async (connection: Connection) => {
    const response = await api.put(`/connections/database/${connection.connection_id}`, connection);
    return response.data;
});

export const deleteConnection = createAsyncThunk('connections/deleteConnection', async (connection_id: number) => {
    await api.delete(`/connections/database/${connection_id}`);
    return connection_id;
});

const connectionSlice = createSlice({
    name: 'connections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchConnections.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchConnections.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchConnections.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(createConnection.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(updateConnection.fulfilled, (state, action) => {
                const index = state.items.findIndex(
                    (connection) => connection.connection_id === action.payload.connection_id
                );
                state.items[index] = action.payload;
            })
            .addCase(deleteConnection.fulfilled, (state, action) => {
                state.items = state.items.filter((connection) => connection.connection_id !== action.payload);
            });
    },
});

export default connectionSlice.reducer;
