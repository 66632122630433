import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { fetchConnections } from '../../../../../redux/slices/connectionSlice';

const SQLNodeForm = ({ data, onChange }) => {
  const dispatch = useDispatch();
  const connections = useSelector((state) => state.connections.items);

  useEffect(() => {
    dispatch(fetchConnections());
  }, [dispatch]);

  const handleInputChange = (field, value) => {
    onChange(field, value);
  };

  // Sort connections alphabetically by the database name
  const sortedConnections = connections.slice().sort((a, b) => {
    if (a.database < b.database) return -1;
    if (a.database > b.database) return 1;
    return 0;
  });

  return (
    <>
      <Form.Group controlId="formNodeDatabase" className="mt-2">
        <Form.Label>Database</Form.Label>
        <Form.Control
          as="select"
          value={data.database || ''}
          onChange={(e) => handleInputChange('database', e.target.value)}
        >
          <option value="">Select a database</option>
          {sortedConnections.map((connection) => (
            <option key={connection.connection_id} value={connection.database}>
              {connection.database}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
      <Form.Group controlId="formNodeQuery" className="mt-2">
        <Form.Label>Query</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={data.query || ''}
          onChange={(e) => handleInputChange('query', e.target.value)}
        />
      </Form.Group>
    </>
  );
};

export default SQLNodeForm;
