import React, { useEffect, useState } from 'react';
import { useHass } from '../HassProvider';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import moment from 'moment';

const HassGraph: React.FC<{ entityId: string }> = ({ entityId }) => {
  const { entities } = useHass();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (entities && entities[entityId]) {
      const entityData = entities[entityId].attributes.data;
      setData(entityData.map(item => ({
        startTime: item.start_time,
        price: item.price_ct_per_kwh
      })));
    }
  }, [entities, entityId]);

  if (!entities) return <div>Loading...</div>;

  const entity = entities[entityId];
  if (!entity) return <div>Entity not found</div>;

  return (
    <div>
      {/* <h3>{entity.attributes.friendly_name}</h3> */}
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="startTime" tickFormatter={(tick) => moment(tick).format('HH')} />
          <YAxis dataKey="price" label={{ value: 'ct/kWh', angle: -90, position: 'insideLeft' }} />
          <Tooltip labelFormatter={(label) => moment(label).format('YYYY-MM-DD HH:mm')} />
          <CartesianGrid stroke="#f5f5f5" />
          <Bar dataKey="price" fill="#ff7300" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HassGraph;
