import { createAsyncThunk } from '@reduxjs/toolkit'
// import {getToken, removeToken, setToken} from '../../utils/HelperFunctions';
import api from '../../services/api';
import history from '../../utils/history';
import { useNavigate } from 'react-router-dom'

export const fetchUserfolders = createAsyncThunk('userfolders/fetchUserfolders', async (_, { rejectWithValue }) => {
    try {
        // const accessToken = getToken();
        // console.log("redux accesstoken: "+accessToken)
        // api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        const response = await api.get('/userfolders');
        return response.data;
    } catch (e) {
        // removeToken();
        return rejectWithValue('');
    }
});
