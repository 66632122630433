import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import api from 'services/api';

const DynamicForm = ({ node, onSave, onChange }) => {
  const { type: nodeType, data: nodeData } = node;
  const [config, setConfig] = useState(null);
  const [formData, setFormData] = useState(nodeData || {});
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState('');

  useEffect(() => {
    setFormData(nodeData || {});
  }, [nodeData]);

  useEffect(() => {
    // Fetch the form configuration from the backend
    const fetchConfig = async () => {
      try {
        const response = await api.get(`/node_type_configs/${nodeType}`);
        setConfig(response.data.config);
      } catch (error) {
        console.error('Error fetching configuration:', error);
      }
    };

    fetchConfig();
  }, [nodeType]);

  const handleLocalInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
    onChange(field, value); // Call the onChange prop
  };

  const handleLabelChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      label: value,
    }));
    onChange('label', value); // Call the onChange prop for label
  };

  const handleSave = () => {
    onSave(formData);
  };

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'Dropdown':
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Label>{field.name}</Form.Label>
            <Form.Control
              as="select"
              value={formData[field.name] || ''}
              onChange={(e) => handleLocalInputChange(field.name, e.target.value)}
            >
              <option value="">Select an option</option>
              {field.DropdownValues && (
                <DropdownOptions
                  url={field.DropdownValues}
                  selectedValue={formData[field.name]} // Pass the selected value
                />
              )}
            </Form.Control>
          </Form.Group>
        );
      case 'longText':
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Label>{field.name}</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={formData[field.name] || ''}
              onChange={(e) => handleLocalInputChange(field.name, e.target.value)}
              onClick={() => handleShowModal(formData[field.name] || '')}
            />
          </Form.Group>
        );
      default:
        return (
          <Form.Group controlId={`form${field.name}`} key={field.name}>
            <Form.Label>{field.name}</Form.Label>
            <Form.Control
              type="text"
              value={formData[field.name] || ''}
              onChange={(e) => handleLocalInputChange(field.name, e.target.value)}
            />
          </Form.Group>
        );
    }
  };

  if (!config) return null;

  return (
    <>
      <Form>
        <Form.Group controlId="formNodeLabel">
          <Form.Label>Node Label</Form.Label>
          <Form.Control
            type="text"
            value={formData.label || ''}
            onChange={(e) => handleLabelChange(e.target.value)}
          />
        </Form.Group>
        {config.fields.map((field) => renderField(field))}
        {/* <Button variant="primary" onClick={handleSave} className="mt-3">
          Save Changes
        </Button> */}
      </Form>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Script Content</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={10}
            value={modalContent}
            onChange={(e) => setModalContent(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const DropdownOptions = ({ url, selectedValue }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await api.get(url);
        setOptions(response.data);
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };

    fetchOptions();
  }, [url]);

  return (
    <>
      {options.map((option) => (
        <option key={option} value={option} selected={option === selectedValue}>
          {option}
        </option>
      ))}
    </>
  );
};

export default DynamicForm;
