import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface Worker {
    worker_id: number;
    worker_name: string;
    priority?: number; // Ensure priority is a number
}

interface SAPConnection {
    connections_sap_id: number;
    connection_sap_name: string;
    sap_host: string;
    sap_sysnr: string;
    sap_client: string;
    sap_user: string;
    sap_password: string;
    language: string;
    maximumnumberofrfcconnections: number; // Changed to match backend
    workers?: Worker[];
}

interface SAPConnectionState {
    items: SAPConnection[];
    status: string | null;
}

const initialState: SAPConnectionState = {
    items: [],
    status: null,
};

export const fetchSAPConnections = createAsyncThunk('sapConnections/fetchSAPConnections', async () => {
    const response = await api.get('/connections/sap');
    return response.data;
});

export const createSAPConnection = createAsyncThunk('sapConnections/createSAPConnection', async (connection: Omit<SAPConnection, 'connections_sap_id'>) => {
    const response = await api.post('/connections/sap', connection);
    return response.data;
});

export const updateSAPConnection = createAsyncThunk('sapConnections/updateSAPConnection', async (connection: SAPConnection) => {
    const response = await api.put(`/connections/sap/${connection.connections_sap_id}`, connection);
    return response.data;
});

export const deleteSAPConnection = createAsyncThunk('sapConnections/deleteSAPConnection', async (connections_sap_id: number) => {
    await api.delete(`/connections/sap/${connections_sap_id}`);
    return connections_sap_id;
});

const connectionSAPSlice = createSlice({
    name: 'sapConnections',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchSAPConnections.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSAPConnections.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchSAPConnections.rejected, (state) => {
                state.status = 'failed';
            })
            .addCase(createSAPConnection.fulfilled, (state, action) => {
                state.items.push(action.payload);
            })
            .addCase(updateSAPConnection.fulfilled, (state, action) => {
                const index = state.items.findIndex(
                    (connection) => connection.connections_sap_id === action.payload.connections_sap_id
                );
                state.items[index] = action.payload;
            })
            .addCase(deleteSAPConnection.fulfilled, (state, action) => {
                state.items = state.items.filter((connection) => connection.connections_sap_id !== action.payload);
            });
    },
});

export default connectionSAPSlice.reducer;
