import AppProvider from 'providers/AppProvider';
import React from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import { useEffect, useState } from 'react';



import { Provider } from 'react-redux';
// import { useSelector, useDispatch } from 'react-redux';
// const dispatch = useDispatch();
import store from './redux/store';

import { fetchUserData } from './redux/slices/authThunk';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


// if (localStorage.getItem('user')) {
//   console.log("local storage user.token: "+JSON.parse(localStorage.getItem('user')).token)
//   localStorage.setItem('token',JSON.parse(localStorage.getItem('user')).token)
// }

if (localStorage.getItem('token')) {
  store.dispatch(fetchUserData());
}


root.render(
  <Provider store={store}>
  <React.StrictMode>
    <AppProvider>
      <SettingsPanelProvider>
        <ChatWidgetProvider>
          <BreakpointsProvider>
            <RouterProvider router={router} />
          </BreakpointsProvider>
        </ChatWidgetProvider>
      </SettingsPanelProvider>
    </AppProvider>
  </React.StrictMode>
  </Provider>
);
