import SettingsPanel from 'components/settings-panel/SettingsPanel';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import useToggleStyle from 'hooks/useToggleStyle';
import { useAppContext } from 'providers/AppProvider';
import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';


const App = () => {
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();

  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();

  const {
    config: { theme, isRTL }
  } = useAppContext();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);

  // const [loggedIn, setLoggedIn] = useState(false)
  // const [username, setusername] = useState('')
  // useEffect(() => {
  //   // Fetch the user username and token from local storage
  //   const user = JSON.parse(localStorage.getItem('user'))
  //   console.log("User: "+JSON.stringify(user))
  //   // If the token/username does not exist, mark the user as logged out
  //   if (!user || !user.token) {
  //     setLoggedIn(false)
  //     return
  //   }
  
  //   // If the token exists, verify it with the auth server to see if it is valid
  //   fetch('http://localhost:5002/users/validatetoken', {
  //     method: 'POST',
  //     headers: {
  //       'Authorization': user.token,
  //     },
  //   })
  //     .then((r) => r.json())
  //     .then((r) => {
  //       setLoggedIn('success' === r.message)
  //       setusername(user.username || '')
  //     })
      
  // }, [])


  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          <Outlet />
          {showSettingPanelButton && (
            <>
              <SettingsToggle />
              <SettingsPanel />
            </>
          )}
        </>
      )}
    </>
  );
};

export default App;
