import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

export const fetchDashboardPages = createAsyncThunk(
    'dashboard/fetchDashboardPages',
    async () => {
        const response = await api.get('/dashboard_pages');
        return response.data;
    }
);

export const fetchDashboardItemData = createAsyncThunk(
    'dashboard/fetchDashboardItemData',
    async ({ entities, start, end }: { entities: string[], start?: string, end?: string }) => {
        const requestBody: any = { entities };
        if (start) {
            requestBody.start = start;
        }
        if (end) {
            requestBody.end = end;
        }
        const response = await api.post('/influx', requestBody);
        return response.data;
    }
);
