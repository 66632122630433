import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import { Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

import PasswordChecklist from "react-password-checklist"


import { connect } from 'react-redux'
import { createListener } from '../../../../redux/slices/listenersThunk';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';


const CreateListener = () => {
    const dispatch = useDispatch();
  // console.log("props: "+JSON.stringify(props))
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const listener_id = useSelector((state: RootStateOrAny) => state.listeners.listeners[props.stateId].listener_id);
  // const status = useSelector((state: RootStateOrAny) => state.listeners.listeners[props.stateId].status);
  const [protocol, setProtocol] = useState<string>()
  const [port, setPort] = useState<string>()
  const [listener_name, setlistener_name] = useState<string>()
  const [auto_start, setAuto_start] = useState<string>('false')
  // const listener_id = useSelector((state: RootStateOrAny) => state.listeners.listeners[props.stateId].listener_id);



  const handleCreateListener = async (e) => {
    
    // console.log("editListener - e: " + JSON.stringify(e))
    try {
        e.preventDefault();
      const body = { protocol, port, listener_name, auto_start }
      // console.log("editListener - body: " + JSON.stringify(body))
      dispatch(createListener(body));
    // props.updateUser(body)
    // console.log(body)
    } catch (error) {
    console.error(error.message)
  }
}


return (

  <>
    <Button variant="phoenix-secondary" onClick={handleShow}>
      Create New
    </Button>

    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
      <Modal.Header>
        <Modal.Title>Create New Listener</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        {/* <Form.Group as={Row} className="mb-3" controlId="formListenerID">
          <Form.Label column sm="2">
            Listener ID
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={listener_id} plaintext readOnly />
          </Col>
        </Form.Group> */}

        {/* <Form.Group as={Row} className="mb-3" controlId="formListenerStatus">
          <Form.Label column sm="2">
            Status
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" defaultValue={status} plaintext readOnly />
          </Col>
        </Form.Group> */}

        <Form.Group as={Row} className="mb-3" controlId="formListenerName">
          <Form.Label column sm="2">
            Name
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" onChange={ev => setlistener_name(ev.target.value)}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formListenerProtocol">
          <Form.Label column sm="2">
            Protocol
          </Form.Label>
          <Col sm="10">
            <Form.Control type="text" placeholder="Normal text" onChange={ev => setProtocol(ev.target.value)}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formListenerPort">
          <Form.Label column sm="2">
            Port
          </Form.Label>
          <Col sm="10">
            <Form.Control type="number" placeholder="Normal text" onChange={ev => setPort(ev.target.value)}/>
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formListenerAutoStart">
          <Form.Label column sm="2">
            Auto start
          </Form.Label>
          <Col sm="10">
            <Form.Control type="string" placeholder="Normal text" defaultValue={auto_start} onChange={ev => setAuto_start(ev.target.value)}/>
          </Col>
        </Form.Group>


      </Modal.Body>
      <Modal.Footer>
        <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
          Close
        </Button>
        <Button variant="phoenix-primary" onClick={(e) => handleCreateListener(e)} startIcon={<FontAwesomeIcon icon={faSave} />}>Save</Button>
      </Modal.Footer>
    </Modal>
  </>
);
}

// export default EditUser;




// const mapStateToProps = (state, ownProps) => {
//   const user_id = ownProps.user_id
//   return {
//     userItem: state.user.users.find(item => item.user_id === user_id)
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     updateUser: user => dispatch(updateUser(user))
//   }
// }


// export default connect(mapStateToProps,mapDispatchToProps)(EditUser)

export default CreateListener