import { useState } from 'react';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { updateDevice } from '../../../../redux/slices/devicesThunk';

const EditDevice = (props) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const device = props.device;
  const [name, setName] = useState(device.device_name);

  const handleUpdateDevice = async (e) => {
    e.preventDefault();
    try {
      const body = { device_id: device.device_id, device_name: name };
      dispatch(updateDevice(body));
      handleClose();
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Button variant="phoenix-secondary" onClick={handleShow}>
        Edit
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
        <Modal.Header>
          <Modal.Title>Edit Device</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="deviceName" className="form-label">Device Name</label>
              <input type="text" className="form-control" id="deviceName" value={name} onChange={(e) => setName(e.target.value)} />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
            Close
          </Button>
          <Button variant="phoenix-primary" onClick={handleUpdateDevice} startIcon={<FontAwesomeIcon icon={faSave} />}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditDevice;
