import React, { useEffect } from "react";
import { Provider } from 'react-redux';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { fetchDevices, deleteDevice } from "../../../../redux/slices/devicesThunk";


import HassEntity from './types/HassEntity';
import HassInputNumber from './types/HassInputNumber';
import HassProvider from './HassProvider';

// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";


const Ems_battery = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state: RootStateOrAny) => state.devices.devices);
    const devicesLoading = useSelector((state: RootStateOrAny) => state.devices.loading);

    useEffect(() => {
        dispatch(fetchDevices());
    }, [dispatch]);

    const handleDelete = async (deviceId) => {
        try {
            dispatch(deleteDevice({ device_id: deviceId }));
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            <h2>Batterij</h2>
            <br />

            

   


            <HassProvider>
                <Table hover size='sm'>
                    <thead>
                        <th>Variabele</th>
                        <th>Waarde</th>
                        <th>Actie</th>
                    </thead>
                    <tbody>
                    <HassEntity entityId="input_boolean.ems_auto_switch_battery_state" />
                    <HassInputNumber entityId="input_number.ems_min_seconds_usage_before_discharging" />
                    </tbody>
                </Table>
            {/* <HassEntity entityId="input_boolean.ems_auto_switch_battery_state" /> */}
            
            </HassProvider>


            
        </div>
    );
};

export default Ems_battery;
