import { createAsyncThunk } from '@reduxjs/toolkit'
// import {getToken, removeToken, setToken} from '../../utils/HelperFunctions';
import api from '../../services/api';
import history from '../../utils/history';
import { useNavigate } from 'react-router-dom'

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async (_, { rejectWithValue }) => {
    try {
        // const accessToken = getToken();
        // console.log("redux accesstoken: "+accessToken)
        // api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        const response = await api.get('/roles');
        return response.data;
    } catch (e) {
        // removeToken();
        return rejectWithValue('');
    }
});


export const updateRole = createAsyncThunk('roles/updateRole', async (payload: object) => {

    const response = await api.put('/roles/' + payload['role_id'], payload);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    return response.data;
});


export const deleteRole = createAsyncThunk('roles/deleteRole', async (payload: object) => {
    var response = await api.delete('/roles/' + payload['role_id']);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    const response2 = { "role_id": payload['role_id'], "status": response.data.status }
    // console.log("listenersthunk - payload: " + payload)
    return response2;
});



export const createRole = createAsyncThunk('roles/createRole', async (payload: object) => {

    const response = await api.post('/roles/', payload);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    return response.data;
});