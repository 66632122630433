import { createSlice } from '@reduxjs/toolkit';

const popupSlice = createSlice({
  name: 'popups',
  initialState: {},
  reducers: {
    openPopup: (state, action) => {
      const { popupId, data } = action.payload;
      state[popupId] = { isOpen: true, data };
    },
    closePopup: (state, action) => {
      const { popupId } = action.payload;
      if (state[popupId]) {
        state[popupId].isOpen = false;
        state[popupId].data = null;
      }
    },
    updatePopupData: (state, action) => {
      const { popupId, data } = action.payload;
      if (state[popupId]) {
        state[popupId].data = { ...state[popupId].data, ...data };
      }
    },
  },
});

export const { openPopup, closePopup, updatePopupData } = popupSlice.actions;

export default popupSlice.reducer;
