import { createSlice } from '@reduxjs/toolkit'
import {fetchListeners, updateListener, startListener, createListener, deleteListener } from './listenersThunk';

const initialState = {
    listeners: [],
    loading: false
};

export const listenersSlice = createSlice({
    name: 'listeners',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // .addCase(signOut.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.userData = {};
        //     state.token = null;
        // })
        // .addCase(login.pending, (state, action) => {
        //     state.loading = true;
        //     state.token = null;
        // })
        // .addCase(login.fulfilled, (state, action) => {
        //     const {token} = action.payload;
        //     state.token = token;
        //     // state.userData = user;
        //     state.loading = false;
        // })
        // .addCase(login.rejected, (state, action) => {
        //     state.loading = false;
        // })




        // ###### FETCH LISTENER
        .addCase(fetchListeners.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchListeners.fulfilled, (state, action) => {
            const listeners = action.payload;
            state.listeners = listeners;
            // state.userData = user;
            state.loading = false;
        })
        .addCase(fetchListeners.rejected, (state, action) => {
            state.loading = false;
            state.listeners = [];
        })




        // ###### UPDATE LISTENER
        .addCase(updateListener.pending, (state, action) => {
            state.updateListenerLoading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(updateListener.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.listeners[index] = action.payload
            state.updateListenerLoading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(updateListener.rejected, (state, action) => {
            state.updateListenerLoading = false;
        })



        // ###### CREATE LISTENER
        .addCase(createListener.pending, (state, action) => {
            state.createListenerLoading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(createListener.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            // const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.listeners = [...state.listeners, action.payload];
            state.createListenerLoading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(createListener.rejected, (state, action) => {
            state.updateListenerLoading = false;
        })



        // ###### DELETE LISTENER
        .addCase(deleteListener.pending, (state, action) => {
            state.deleteListenerLoading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(deleteListener.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            // const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.listeners = state.listeners.filter(listener => action.payload.listener_id !== listener.listener_id)
            // users: state.users.filter(user => action.payload !== user.user_id)
            state.deleteListenerLoading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(deleteListener.rejected, (state, action) => {
            state.deleteListenerLoading = false;
        })



        // ###### START LISTENER
        .addCase(startListener.pending, (state, action) => {
            const index = state.listeners.findIndex((e) => e.listener_id === action.meta.arg.listener_id);
            state.listeners[index].loading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(startListener.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.listeners[index].status = "Started"
            state.listeners[index].loading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(startListener.rejected, (state, action) => {
            const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.listeners[index].status = "Start failed"
            state.listeners[index].loading = false;
        })
    },
})


export const {} = listenersSlice.actions;

export default listenersSlice.reducer;