import { Nav, Navbar } from 'react-bootstrap';
import { routes } from 'sitemap';
import { capitalize } from 'helpers/utils';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import {
  UilArrowFromRight,
  UilLeftArrowToLeft
} from '@iconscout/react-unicons';

import { useAppContext } from 'providers/AppProvider';
import classNames from 'classnames';
import Button from 'components/base/Button';
import NavbarTopNav from '../navbar-horizontal/NavbarTopNav';
import { useBreakpoints } from 'providers/BreakpointsProvider';
import NavbarVerticalCollapseProvider from './NavbarVerticalCollapseProvider';



// import { fetchDashboardPages, fetchDashboardItemData } from "./../../../redux/slices/dashboardThunk";

const NavbarVerical = (props) => {
const loggedIn = props.loggedIn

  const {
    config: {
      navbarPosition,
      openNavbarVertical,
      navbarVerticalAppearance,
      isNavbarVerticalCollapsed
    },
    setConfig
  } = useAppContext();

  const { breakpoints } = useBreakpoints();



  // const dynamicPages = useSelector((state: RootStateOrAny) => state.dashboard.pages);
  const dispatch = useDispatch();

  // useEffect(() => {
    
  //   dispatch(fetchDashboardPages());

  // }, [dispatch]);


  // useEffect(() => {
    
    // dispatch(fetchDashboardPages());

    // console.log(routes[routes.findIndex(item => item.label === 'dashboard')].pages)
    // dynamicPages.forEach(dynamicPage => {
    //   if(routes[routes.findIndex(item => item.label === 'dashboard')].pages.findIndex(item => item.name === dynamicPage.dashboard_page_name) == -1){
    //     routes[routes.findIndex(item => item.label === 'dashboard')].pages.push({
    //       name: dynamicPage.dashboard_page_name,
    //       icon: 'home',
    //       active: true,
    //       flat: true,
    //       path: '/apps/ems/dashboard?dashboard_page_id='+dynamicPage.dashboard_page_id,
    //       pathName: 'default-dashboard',
    //     })
    //     // console.log("Nav routes updated")
    //   }
    // });
  


  // },[dynamicPages]);



  return (
    <NavbarVerticalCollapseProvider>
      <Navbar
        className={classNames('navbar-vertical', {
          'navbar-darker': navbarVerticalAppearance === 'darker'
        })}
        expand="lg"
        variant=""
      >
        <Navbar.Collapse id="navbarVerticalCollapse" in={openNavbarVertical}>
          <div className="navbar-vertical-content">
            <Nav className="flex-column" as="ul" id="navbarVerticalNav">
              {routes.map(route => (
                <Nav.Item key={route.label}>
                  {!route.labelDisabled && (
                    <>
                      <p className="navbar-vertical-label">
                        {capitalize(route.label)}
                      </p>
                      <hr className="navbar-vertical-line" />
                    </>
                  )}
                  <NavbarVerticalMenu level={1} routes={route.pages} />
                </Nav.Item>
              ))}
                             
            </Nav>

            {navbarPosition === 'combo' && breakpoints.down('lg') && (
              <div className="move-container">
                <div className="navbar-vertical-divider">
                  <hr className="navbar-vertical-hr" />
                </div>
                <NavbarTopNav />
              </div>
            )}
          </div>
        </Navbar.Collapse>
        <div className="navbar-vertical-footer">
          <Button
            className="navbar-vertical-toggle border-0 fw-semi-bold w-100 white-space-nowrap d-flex align-items-center"
            onClick={() => {
              setConfig({
                isNavbarVerticalCollapsed: !isNavbarVerticalCollapsed
              });
            }}
          >
            {isNavbarVerticalCollapsed ? (
              <UilArrowFromRight size={16} className="mb-1" />
            ) : (
              <>
                <UilLeftArrowToLeft size={16} className="mb-1" />
                <span className="ms-2">Collapsed View</span>
              </>
            )}
          </Button>
        </div>
      </Navbar>
    </NavbarVerticalCollapseProvider>
  );
};

export default NavbarVerical;
