// import axios from "axios"
import api from "services/api"
import { 
    FETCH_USERS_FAILURE, 
    FETCH_USERS_REQUEST, 
    FETCH_USERS_SUCCESS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,
    CREATE_USER_REQUEST,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE
} from "./userTypes"


const user = JSON.parse(localStorage.getItem('user'))
// console.log('axios default header: '+user.token)
// axios.defaults.headers.common['Authorization'] = user.token;

const fetchUserRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const fetchUserSuccess = users => {
    return {
        type: FETCH_USERS_SUCCESS,
        payload: users
    }
}

const fetchUserFailure = error => {
    return {
        type: FETCH_USERS_FAILURE,
        payload: error
    }
}

export const fetchUsers = () => {
    return (dispatch) => {
        dispatch(fetchUserRequest)
        api.get('/users')
            .then(response => {
                const users = response.data
                dispatch(fetchUserSuccess(users))
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(fetchUserFailure(errorMsg))
            })
    }
}

export const updateUser = (user) => {
    console.log("Update user: " + JSON.stringify(user))
    return (dispatch) => {
        dispatch(updateUserRequest(user))
        api.put('/users/'+user.user_id,user)
            .then(response => {
                const user = response.data
                dispatch(updateUserSuccess(user))
            })
            .catch(error => {
                const errorMsg = error.message
                dispatch(updateUserFailure(errorMsg))
            })
    }
}

const updateUserRequest = user => {
    return {
        type: UPDATE_USER_REQUEST,
        payload: user
    }
}

const updateUserFailure = error => {
    return {
        type: UPDATE_USER_FAILURE,
        payload: error
    }
}

const updateUserSuccess = user => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: user
    }
}

export const createUser = (user) => {
    console.log("Create user: " + JSON.stringify(user))
    return (dispatch) => {
        dispatch(createUserRequest(user))
        api.post('/users/',user)
        .then(response => {
            const user = response.data
            dispatch(createUserSuccess(user))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(createUserFailure(errorMsg))
        })
    }
}

const createUserRequest = user => {
    return {
        type: CREATE_USER_REQUEST,
        payload: user
    }
}

const createUserFailure = error => {
    return {
        type: CREATE_USER_FAILURE,
        payload: error
    }
}

const createUserSuccess = user => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: user
    }
}



export const deleteUser = (user_id) => {
    // console.log("Delete user: " + JSON.stringify(payload))
    return (dispatch) => {
        console.log("Delete user, in dispatch: "+user_id)
        dispatch(deleteUserRequest(user_id))

        api.delete('/users/'+user_id)
        .then(response => {
            const successMsg = response.data
            dispatch(deleteUserSuccess(user_id))
        })
        .catch(error => {
            const errorMsg = error.message
            dispatch(deleteUserFailure(errorMsg))
        })
    }
}


const deleteUserRequest = user_id => {
    console.log("deleteUserRequest")
    return {
        type: DELETE_USER_REQUEST,
        payload: user_id
    }
}

const deleteUserFailure = error => {
    return {
        type: DELETE_USER_FAILURE,
        payload: error
    }
}

const deleteUserSuccess = user_id => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: user_id
    }
}