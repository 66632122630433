import { createSlice } from '@reduxjs/toolkit'
import {fetchUserData, login, signOut} from './authThunk';

const initialState = {
    token: null,
    loading: false,
    userData: {}
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(signOut.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = {};
            state.token = null;
        })
        .addCase(login.pending, (state, action) => {
            state.loading = true;
            state.token = null;
        })
        .addCase(login.fulfilled, (state, action) => {
            const {token} = action.payload;
            state.token = token;
            // state.userData = user;
            state.loading = false;
        })
        .addCase(login.rejected, (state, action) => {
            state.loading = false;
        })
        .addCase(fetchUserData.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchUserData.fulfilled, (state, action) => {
            // const {accessToken, username, environment_name, ha_url, ha_token} = action.payload;
            // state.token = accessToken;
            // state.username = username;
            // state.loading = false;
            // state.ha_url = ha_url;
            // state.environment_name = environment_name;
            // state. ha_token = ha_token;
            Object.assign(state, action.payload, { loading: false });
            state.token = action.payload.accessToken;
            // state.ha_url = "http://100.117.129.87:8123"
            // state.ha_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJiMzQ5ZjllNTMxMjc0YmZiOWZiMjYwZmJiMzVmZDhiYyIsImlhdCI6MTcxNjY2MjkxMSwiZXhwIjoyMDMyMDIyOTExfQ.YiMH2Dju3tEeY19Eivu--yGiFAed4spt9bRtdh7AZxU"
        })
        .addCase(fetchUserData.rejected, (state, action) => {
            state.loading = false;
            state.userData = {};
            state.token = null;
        })
    },
})


export const {} = authSlice.actions;

export default authSlice.reducer;