import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import api from 'services/api';
import { unwrapResult } from '@reduxjs/toolkit';

import { fetchBlocks, fetchBlock, updateBlock, deleteBlock } from '../../../../redux/slices/blocksSlice'; // Adjust the import path

interface Block {
  block_id: string;
  block_name: string;
  block_description: string;
  block_type_id: number; // Assuming there's a block_type_id in your block
  block_fields: Array<Object>;
}

interface BlockTypeField {
  block_type_field_id: number;
  block_type_id: number;
  block_type_field_name: string;
  block_type_field_type: string;
}

const PopupEditBlock: React.FC = () => {
  const { itemId } = useParams<{ itemId: string }>();
  const popupId = `popup_${itemId}`;
  const [block, setBlock] = useState<Block | null>(null);
  const [blockTypeFields, setBlockTypeFields] = useState<BlockTypeField[]>([]);
  const dispatch = useDispatch();


  useEffect(() => {
    const fetchBlockData = async () => {
      try {
        // dispatch(fetchBlock({ block_id: itemId }));
        // const fetchedBlock = unwrapResult(resultAction);
        // setBlock(fetchedBlock);
        const blockDetails = await api.get('/blocks/'+itemId)
        // Fetch block type fields from API
        setBlock(blockDetails.data)
        const response = await api.get('/blockTypeFields');
        setBlockTypeFields(response.data);
      } catch (error) {
        console.error('Failed to fetch block or block type fields', error);
      }
    };

    fetchBlockData();
  }, [dispatch, itemId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (block) {
      const { name, value } = e.target;
      setBlock({ ...block, [name]: value });
    }
  };

  const handleSave = () => {
    if (block) {
      window.localStorage.setItem(popupId, JSON.stringify(block));
      dispatch(updateBlock(block));
    }
  };

  const handleClose = () => {
    window.close();
  };

  if (!block) return <div>Loading...</div>;

  return (
    <div className="popup">
      <h1>Edit Block</h1>
      <Form>
        <Form.Group as={Row} key="block_name" controlId="block_name">
          <Form.Label column sm={3}>Block Name</Form.Label>
          <Col sm={9}>
            <Form.Control
              type="text"
              name="block_name"
              value={block.block_name}
              onChange={handleChange}
            />
          </Col>
        </Form.Group>
        {blockTypeFields.filter(field => field.block_type_id === block.block_type_id).map(field => (
          <Form.Group as={Row} key={field.block_type_field_id} controlId={field.block_type_field_name}>
            <Form.Label column sm={3}>{field.block_type_field_name}</Form.Label>
            <Col sm={9}>
              <Form.Control
                type="text"
                name={field.block_type_field_name}
                value={JSON.stringify(block.block_fields)}
                onChange={handleChange}
              />
            </Col>
          </Form.Group>
        ))}
        <Button variant="primary" onClick={handleSave}>Save</Button>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Form>
    </div>
  );
};

export default PopupEditBlock;
