import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { useSelector, RootStateOrAny } from 'react-redux';
import {
  createConnection,
  createLongLivedTokenAuth,
  subscribeEntities,
  HassEntities,
} from 'home-assistant-js-websocket';

interface HassContextProps {
  entities: HassEntities | null;
  connection: any;
  callService: (domain: string, service: string, serviceData: any) => void;
}

const HassContext = createContext<HassContextProps | undefined>(undefined);

interface HassProviderProps {
  children: ReactNode;
}

const HassProvider = ({ children }: HassProviderProps): ReactNode => {
  // const { url, token } = useSelector((state: RootStateOrAny) => state.hass);
  const url = useSelector((state: RootStateOrAny) => state.auth.ha_url);
  const token = useSelector((state: RootStateOrAny) => state.auth.ha_token);
  const [entities, setEntities] = useState<HassEntities | null>(null);
  const [connection, setConnection] = useState<any>(null);

  useEffect(() => {
    if (url && token) {
      const init = async () => {
        const auth = createLongLivedTokenAuth(url, token);
        const conn = await createConnection({ auth });

        subscribeEntities(conn, (entities) => setEntities(entities));
        setConnection(conn);
      };

      init();
    }
  }, [url, token]);

  const callService = (domain: string, service: string, serviceData: any) => {
    if (connection) {
      connection.sendMessagePromise({
        type: 'call_service',
        domain,
        service,
        service_data: serviceData,
      });
    }
  };

  return (
    <HassContext.Provider value={{ entities, connection, callService }}>
      {children}
    </HassContext.Provider>
  );
};

export const useHass = (): HassContextProps => {
  const context = useContext(HassContext);
  if (!context) {
    throw new Error('useHass must be used within a HassProvider');
  }
  return context;
};

export default HassProvider;
