import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ReactFlow, {
    Controls,
    Background,
    applyNodeChanges,
    applyEdgeChanges,
    addEdge,
    useReactFlow,
    ReactFlowProvider,
} from 'reactflow';
import 'reactflow/dist/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import DefaultLandingNavbar from 'components/navbars/default-landing-navbar/DefaultLandingNavbar';
import useSettingsMountEffect from 'hooks/useSettingsMountEffect';

import { useSelector, RootStateOrAny } from 'react-redux';
import HeroHeader from 'components/modules/landing/alternate/HeroHeader';
import Brands from 'components/modules/landing/default/Brands';
import Footer from 'components/modules/landing/alternate/Footer';
import logo from 'assets/img/icons/logo-orange.png';

import Cta from 'components/modules/landing/alternate/Cta';

import { getBackendUrl } from '../../../../services/backendUrl'; // Import getBackendUrl

const initialNodes = [
    {
        id: '1',
        data: { label: 'Hello' },
        position: { x: 0, y: 0 },
        type: 'input',
    },
    {
        id: '2',
        data: { label: 'World' },
        position: { x: 100, y: 100 },
    },
];

const initialEdges = [
    { id: '1-2', source: '1', target: '2', label: 'to the', type: 'step' },
];

const Sidebar = () => {
    const onDragStart = (event, nodeType) => {
        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.effectAllowed = 'move';
    };

    return (
        <aside>
            <div className="description">Task Library</div>
            <div className="dndnode input" onDragStart={(event) => onDragStart(event, 'input')} draggable>
                Input Node
            </div>
            <div className="dndnode" onDragStart={(event) => onDragStart(event, 'default')} draggable>
                Default Node
            </div>
            <div className="dndnode output" onDragStart={(event) => onDragStart(event, 'output')} draggable>
                Output Node
            </div>
        </aside>
    );
};

const PopupFlowEditor = () => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const stateLoading = useSelector((state: RootStateOrAny) => state.auth.loading);
    const token = useSelector((state: RootStateOrAny) => state.auth.token);

    useSettingsMountEffect({
        disableNavigationType: true,
        disableHorizontalNavbarAppearance: true,
        disableVerticalNavbarAppearance: true,
        disableHorizontalNavbarShape: true,
    });

    useEffect(() => {
        const validateToken = async () => {
            console.log('stateLoading: ' + stateLoading);
            if (!stateLoading) {
                if (token != null) {
                    console.log('MainLayout within token != loop. Token ' + token);

                    const baseURL = await getBackendUrl(); // Fetch the backend URL dynamically

                    // If the token exists, verify it with the auth server to see if it is valid
                    fetch(baseURL + '/users/validatetoken', {
                        method: 'POST',
                        headers: {
                            Authorization: token,
                        },
                    })
                        .then((r) => r.json())
                        .then((r) => {
                            setLoggedIn('success' === r.message);
                            setIsLoading(false);
                        });
                    return;
                }

                if (token == null && isLoading) {
                    setIsLoading(false);
                    return;
                }
            }

            if (isLoading) {
                console.log('if loop within useEffect mainlayout token: ' + token + ' loading: ' + isLoading);
                return;
            }
            console.log('After if loading loop');

            console.log('final comment - Loading: ' + isLoading + ' StateLoading: ' + stateLoading + ' Token: ' + token);
        };

        validateToken();
    }, [stateLoading, token, isLoading]);

    const [nodes, setNodes] = useState(initialNodes);
    const [edges, setEdges] = useState(initialEdges);
    const [showModal, setShowModal] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        []
    );

    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        []
    );

    const onNodeClick = (event, node) => {
        setSelectedNode(node);
        setInputValue(node.data.label); // Initialize with current label
        setShowModal(true);
    };

    const handleClose = () => setShowModal(false);

    const handleSave = () => {
        if (selectedNode) {
            setNodes((nds) =>
                nds.map((node) =>
                    node.id === selectedNode.id ? { ...node, data: { label: inputValue } } : node
                )
            );
        }
        setShowModal(false);
    };

    const reactFlowInstance = useCallback(useReactFlow(), []);

    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onDrop = (event) => {
        event.preventDefault();

        const type = event.dataTransfer.getData('application/reactflow');
        if (!type) {
            return;
        }

        const position = reactFlowInstance.project({
            x: event.clientX,
            y: event.clientY,
        });
        const newNode = {
            id: `${+new Date()}`,
            type,
            position,
            data: { label: `${type} node` },
        };

        setNodes((nds) => nds.concat(newNode));
    };

    const styles = {
        width: '100%',
        height: 300,
    };

    return (
        <>
            <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
                <div
                    style={{
                        height: '65px',
                        background: '#fff',
                        color: '#6e7891',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'left',
                        paddingLeft: '10px',
                        paddingTop: '5px',
                        borderBottom: '1px solid #cbd0dd',
                    }}
                >
                    <div className="d-flex align-items-top">
                        <img src={logo} alt={process.env.REACT_APP_COMPANY_NAME} height={25} />
                        <p className="ms-2">Flow Editor</p>
                        <p style={{ paddingLeft: '5px' }}>xxFlowname</p>
                    </div>
                </div>
                <div style={{ flex: 1, display: 'flex' }}>
                    <Sidebar />
                    <div id="reactflow-wrapper" style={{ flex: 1, height: '100%' }} onDrop={onDrop} onDragOver={onDragOver}>
                        <ReactFlow
                            nodes={nodes}
                            onNodesChange={onNodesChange}
                            edges={edges}
                            onEdgesChange={onEdgesChange}
                            onConnect={onConnect}
                            onNodeClick={onNodeClick}
                            style={styles}
                            fitView
                        >
                            <Background />
                            <Controls />
                        </ReactFlow>

                        <Modal show={showModal} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Edit Node</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group controlId="formNodeLabel">
                                        <Form.Label>Node Label</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                        />
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button variant="primary" onClick={handleSave}>
                                    Save Changes
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

const App = () => (
    <div className="bg-white dark__bg-1200">
        <ReactFlowProvider>
            <PopupFlowEditor />
        </ReactFlowProvider>
    </div>
);

export default App;
