import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownBlocks from 'components/common/ActionDropdownBlocks';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useState, useEffect } from 'react';
import SearchBox from 'components/common/SearchBox';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBlocks, fetchBlock, updateBlock, deleteBlock } from '../../../../redux/slices/blocksSlice'; // Adjust the import path
import { Container, Row, Col } from 'react-bootstrap';
import { unwrapResult } from '@reduxjs/toolkit';

// Define or import the Block interface
interface Block {
  block_id: number;
  block_name: string;
  block_description: string;
  block_type_name: string;
  block_type_id: number;
}

const ListBlocks = () => {
  const dispatch = useDispatch();
  const blocks = useSelector((state: any) => state.blocks.blocks);
  const loading = useSelector((state: any) => state.blocks.loading);

  useEffect(() => {
    dispatch(fetchBlocks());
  }, [dispatch]);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key && event.key.startsWith('popup_')) {
        const updatedBlock = JSON.parse(event.newValue);
        console.log(`Updated block from storage event:`, updatedBlock);
        dispatch(updateBlock(updatedBlock));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch]);

  const [selectedBlock, setSelectedBlock] = useState<Block | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const handleEdit = (block: Block) => {
    setSelectedBlock(block);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedBlock(null);
  };

  const handleAction = (block_id: number, action: string) => {
    const block = blocks.find((block: Block) => block.block_id === block_id);
    if (action === 'edit' && block) {
      handleEdit(block);
    } else if (action === 'edit_popout' && block) {
      handleEditPopOut(block);
    } else if (action === 'remove') {
      dispatch(deleteBlock(block_id));
    }
  };

  const handleEditPopOut = async (block: Block) => {
    const popupId = `popup_${block.block_id}`;
    const url = `/popups/editBlock/${block.block_id}`;
    
    try {
        // dispatch(fetchBlock(block));
        // unwrapResult(resultAction);
        
        console.log(`Storing data for ${popupId}`, block);
        window.localStorage.setItem(popupId, JSON.stringify(block));
        window.open(url, '_blank', 'noopener,noreferrer');
    } catch (error) {
        console.error('Failed to fetch block:', error);
    }
};

  const columns: ColumnDef<Block>[] = [
    {
      accessorKey: 'block_name',
      header: 'Block Name',
    },
    {
      accessorKey: 'block_description',
      header: 'Block Description',
    },
    {
      accessorKey: 'block_type_name',
      header: 'Block Type',
    },
    {
      id: 'action',
      cell: ({ row }) => (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <ActionDropdownBlocks block_id={row.original.block_id} onAction={handleAction} />
          </RevealDropdown>
        </RevealDropdownTrigger>
      ),
      meta: {
        headerProps: { style: { width: '7%' } },
        cellProps: { className: 'text-end' },
      },
    },
  ];

  const table = useAdvanceTable({
    data: blocks,
    columns,
    pageSize: 5,
    pagination: true,
    selection: true,
    sortable: true,
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  return (
    <Container fluid className="p-0">
      <Row className="m-0">
        <Col className="p-0">
          <AdvanceTableProvider {...table}>
            <SearchBox
              placeholder="Search..."
              size="sm"
              onChange={handleSearchInputChange}
              className="mx-auto mb-4"
            />
            <AdvanceTable
              tableProps={{
                size: 'sm',
                className: 'phoenix-table fs-9 mb-0 border-top border-translucent w-100',
              }}
              rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
            />
            <AdvanceTableFooter navBtn />
          </AdvanceTableProvider>
        </Col>
      </Row>

    </Container>
  );
};

export default ListBlocks;
