import { createSlice } from '@reduxjs/toolkit'
import { fetchDevices, updateDevice, deleteDevice, createDevice } from './devicesThunk';

const initialState = {
    devices: [],
    loading: false
};

export const devicesSlice = createSlice({
    name: 'devices',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // Fetch Devices
        .addCase(fetchDevices.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchDevices.fulfilled, (state, action) => {
            const devices = action.payload;
            state.devices = devices;
            state.loading = false;
        })
        .addCase(fetchDevices.rejected, (state, action) => {
            state.loading = false;
            state.devices = [];
        })

        // Create Device
        .addCase(createDevice.pending, (state, action) => {
            state.createDeviceLoading = true;
        })
        .addCase(createDevice.fulfilled, (state, action) => {
            state.devices = [...state.devices, action.payload];
            state.createDeviceLoading = false;
        })
        .addCase(createDevice.rejected, (state, action) => {
            state.createDeviceLoading = false;
        })

        // Update Device
        .addCase(updateDevice.pending, (state, action) => {
            state.updateDeviceLoading = true;
        })
        .addCase(updateDevice.fulfilled, (state, action) => {
            const index = state.devices.findIndex((e) => e.device_id === action.payload.device_id)
            state.devices[index] = action.payload
            state.updateDeviceLoading = false;
        })
        .addCase(updateDevice.rejected, (state, action) => {
            state.updateDeviceLoading = false;
        })

        // Delete Device
        .addCase(deleteDevice.pending, (state, action) => {
            state.deleteDeviceLoading = true;
        })
        .addCase(deleteDevice.fulfilled, (state, action) => {
            state.devices = state.devices.filter(device => action.payload.device_id !== device.device_id)
            state.deleteDeviceLoading = false;
        })
        .addCase(deleteDevice.rejected, (state, action) => {
            state.deleteDeviceLoading = false;
        })
    },
})

export const {} = devicesSlice.actions;

export default devicesSlice.reducer;
