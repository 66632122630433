import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import { Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import PasswordChecklist from "react-password-checklist"


import { connect } from 'react-redux'
import { createUser } from '../../redux';

const CreateUser = (props) => {
  // console.log("props createUser: "+JSON.stringify(props))
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
    console.log("Create handleShow")
  }

  // const user_id = props.user_id;
  const [password, setPassword] = useState<string>();
  const [passwordForm, setPasswordForm] = useState<string>('');
  const [passwordAgain, setPasswordAgain] = useState<string>('')
  const [mail, setMail] = useState<string>()
  const [username, setUsername] = useState<string>()
  const [home_folder, setHome_folder] = useState<number>()



  const createUser = async (e) => {
    e.preventDefault();

    try {
      const body = { username, password, home_folder, mail }
      console.log(body)
      props.createUser(body)
    } catch (error) {
      console.error(error.message)
    }
  }


  return (

    <>
      <Button variant="phoenix-primary" onClick={handleShow} startIcon={<FontAwesomeIcon icon={faPlus} />}>
        Create
      </Button>

      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
        <Modal.Header>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group as={Row} className="mb-3" controlId="formUsername">
            <Form.Label column sm="2">
              Username
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Normal text"  onChange={ev => setUsername(ev.target.value)} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formUserMail">
            <Form.Label column sm="2">
              Email
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Normal text" onChange={ev => setMail(ev.target.value)} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="FormUserPassword">
            <Form.Label column sm="2">
              Password
            </Form.Label>
            <Col sm="10">
              <Form.Control type="password" placeholder="Password" onChange={
                ev => {
                  setPasswordForm(ev.target.value)
                  setPassword(ev.target.value)
                }} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="FormUserPasswordAgain">
            <Form.Label column sm="2">
              Password again
            </Form.Label>
            <Col sm="10">
              <Form.Control type="password" placeholder="Password" onChange={ev => setPasswordAgain(ev.target.value)} />
            </Col>
          </Form.Group>

          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={5}
            value={passwordForm}
            valueAgain={passwordAgain}
            onChange={(isValid) => { }}
          />

          <Form.Group as={Row} className="mb-3" controlId="FormUserHomeFolder">
            <Form.Label column sm="2">
              Home folder
            </Form.Label>
            <Col sm="10">
              <Form.Control type="text" placeholder="Normal text" onChange={ev => setHome_folder(Number(ev.target.value))} />
            </Col>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
            Close
          </Button>
          <Button variant="phoenix-primary" onClick={ev => createUser(ev)} startIcon={<FontAwesomeIcon icon={faSave} />}>Save</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// export default EditUser;




// const mapStateToProps = (state) => {
//   // const user_id = ownProps.user_id
//   return {
//     // userItem: state.user.users.find(item => item.user_id === user_id)
//   }
// }

const mapDispatchToProps = dispatch => {
  return {
    createUser: user => dispatch(createUser(user))
  }
}


export default connect(null, mapDispatchToProps)(CreateUser)
