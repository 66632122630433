import { combineReducers } from "redux";
// import cakeReducer from "./cake/cakeReducer";
// import iceCreamReducer from "./iceCream/iceCreamReducer";
import userReducer from './user/userReducer'
import authReducer from './slices/auth'
import fileReducer from './slices/files'
import listenerReducer from './slices/listeners'
import userfoldersReducer from './slices/userfolders'
import rolesReducer from './slices/roles'
import devicesReducer from './slices/devices'
import configReducer from './slices/config'
import influxReducer from './slices/influx'
// import dashboardSlice from "./slices/dashboardSlice";
import blocksReducer from './slices/blocksSlice';
import popupReducer from './slices/popupSlice';
import flowReducer from './slices/flowsSlice';
import connectionReducer from './slices/connectionSlice';
import workerReducer from './slices/workersSlice';
import sapConnectionsReducer from './slices/connectionSAPSlice';

const rootReducer = combineReducers({
    // cake: cakeReducer,
    // iceCream: iceCreamReducer,
    user: userReducer,
    auth: authReducer,
    files: fileReducer,
    listeners: listenerReducer,
    userfolders: userfoldersReducer,
    roles: rolesReducer,
    devices: devicesReducer,
    config: configReducer,
    influx: influxReducer,
    // dashboard: dashboardSlice,
    blocks: blocksReducer,
    popups: popupReducer,
    flows: flowReducer,
    connections: connectionReducer,
    workers: workerReducer,
    sapConnections: sapConnectionsReducer,

})

export default rootReducer