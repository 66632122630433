import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { fetchUserData } from "../../../redux/slices/authThunk";

// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";


const LoggedInUser = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state: RootStateOrAny) => state.devices.devices);
    const devicesLoading = useSelector((state: RootStateOrAny) => state.devices.loading);
    const enVironmentName = useSelector((state: RootStateOrAny) => state.auth.environment_name);
    const username = useSelector((state: RootStateOrAny) => state.auth.username);
    useEffect(() => {
        dispatch(fetchUserData());
    }, [dispatch]);



    return (
        <div className="fw-bolder">
          {username} - {enVironmentName}
        </div>
    );
};

export default LoggedInUser;
