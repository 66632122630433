import React, { useState, useEffect } from 'react';
import { useHass } from '../HassProvider';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

interface HassInputSelectProps {
  entityId: string;
}

const HassInputSelect: React.FC<HassInputSelectProps> = ({ entityId }) => {
  const { entities, callService } = useHass();
  const [value, setValue] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (entities && entities[entityId]) {
      const entity = entities[entityId];
      setValue(entity.state);
      setOptions(entity.attributes.options);
    }
  }, [entities, entityId]);

  if (!entities) return <div>Loading...</div>;

  const entity = entities[entityId];
  if (!entity) return <div>Entity not found</div>;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = () => {
    if (value !== undefined) {
      callService('input_select', 'select_option', { entity_id: entityId, option: value });
    }
  };

  return (
    <tr>
      <td>{entity.attributes.friendly_name}</td>
      <td>
      <select className='form-select' value={value} onChange={handleChange}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      </td>
      <td>
      <ButtonGroup aria-label="Basic example">
      <Button variant="phoenix-primary" onClick={handleSubmit} startIcon={<FontAwesomeIcon icon={faSave} />}>
            Save
        </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

export default HassInputSelect;
