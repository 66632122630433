import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../services/api';

// Fetch Devices
export const fetchDevices = createAsyncThunk('devices/fetchDevices', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/devices');
        return response.data;
    } catch (error) {
        return rejectWithValue('');
    }
});

// Update Device
export const updateDevice = createAsyncThunk('devices/updateDevice', async (payload: object) => {
    const response = await api.put('/devices/' + payload['device_id'], payload);
    return response.data;
});

// Delete Device
export const deleteDevice = createAsyncThunk('devices/deleteDevice', async (payload: object) => {
    const response = await api.delete('/devices/' + payload['device_id']);
    return { device_id: payload['device_id'], status: response.data.status };
});

// Create Device
export const createDevice = createAsyncThunk('devices/createDevice', async (payload: object) => {
    const response = await api.post('/devices/', payload);
    return response.data;
});
