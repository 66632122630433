import React, { Fragment, useEffect, useState } from "react";
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';

// import { ChangeEvent } from 'react';
// import SearchBox from 'components/common/SearchBox';

import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";

// import EditListener from "./editListener";
// import CreateListener from "./createListener";

import { fetchRoles, deleteRole } from "../../../../redux/slices/rolesThunk";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import EditRole from "./editRole";
import CreateRole from "./createRole";

const ListRoles = () => {

    const dispatch = useDispatch();
    const roles = useSelector((state: RootStateOrAny) => state.roles.roles)
    const rolesLoading = useSelector((state: RootStateOrAny) => state.roles.loading)

    useEffect(() => {
        // getFiles();
        dispatch(fetchRoles());
    }, []);

    const handleDelete = async (role_id) => {
        // e.preventDefault();
        try {

          // const body = { username, password, home_folder, mail }
          const body = {role_id}
          console.log(body)
          console.log("handleDelete: "+role_id)
          // const payload = { user_id }
          dispatch(deleteRole(body))
        } catch (error) {
          console.error(error.message)
        }
      }





    return (
        <div>
            <h1>Roles</h1>

            <Table hover size='sm' >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(roles).map(([key, role], i) => (
                        <tr key={i}>
                            <td>{roles[i].role_id}</td>
                            <td>{roles[i].name}</td>
                            <td>
                                <ButtonGroup aria-label="Basic example">
                                    <EditRole stateId={i} />
                                    <Button variant="phoenix-secondary" onClick={() => handleDelete(roles[i].role_id)}>Delete</Button>
                                </ButtonGroup>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

                        <CreateRole />


        </div>

    )
};





export default ListRoles;