import React, { useState, useEffect } from 'react';
import { useHass } from '../HassProvider';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

const HassInputNumber: React.FC<{ entityId: string }> = ({ entityId }) => {
  const { entities, callService } = useHass();
  const [value, setValue] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (entities && entities[entityId]) {
      setValue(parseFloat(entities[entityId].state));  // Initial state from entity
    }
  }, [entities, entityId]);

  if (!entities) return <div>Loading...</div>;

  const entity = entities[entityId];
  if (!entity) return <div>Entity not found</div>;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(parseFloat(e.target.value));
  };

  const handleSubmit = () => {
    if (value !== undefined) {
      callService('input_number', 'set_value', { entity_id: entityId, value });
    }
  };

  return (
    <tr>
      <td>{entity.attributes.friendly_name}</td>
      <td>
      <div className="input-group">
        <input
        type="number"
        className="form-control"
        value={value !== undefined ? value : ''}  // Ensure value is defined before displaying
        onChange={handleChange}
        min={entity.attributes.min}
        max={entity.attributes.max}
        step={entity.attributes.step}
      />
      <span className="input-group-text">{entity.attributes.unit_of_measurement}</span>
      </div>
      </td>
      <td>
      <ButtonGroup aria-label="Basic example">
      <Button variant="phoenix-primary" onClick={handleSubmit} startIcon={<FontAwesomeIcon icon={faSave} />}>
            Save
        </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};

export default HassInputNumber;
