import React from 'react';
import { Dropdown } from 'react-bootstrap';

type ActionDropdownConnectionsProps = {
  connection_id: number;
  onAction: (connection_id: number, action: string) => void;
};

const ActionDropdownConnections: React.FC<ActionDropdownConnectionsProps> = ({ connection_id, onAction }) => {
  return (
    <>
      <Dropdown.Item eventKey="1" onClick={() => onAction(connection_id, 'edit')}>Edit</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4" className="text-danger" onClick={() => onAction(connection_id, 'remove')}>
        Remove
      </Dropdown.Item>
    </>
  );
};

export default ActionDropdownConnections;
