import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

interface ScheduleDetails {
  worker_name: string;
  scheduled_datetime: string;
  scheduled_timezone: string;
  recurrence: {
    frequency: string;
    interval: number;
    days_of_week?: number[];  // Optional for daily recurrence
    days_of_month?: number[];  // Optional for monthly recurrence
  };
}

interface FlowSubmitScreenProps {
  showModal: boolean;
  handleClose: () => void;
  handleSubmit: (scheduleDetails: ScheduleDetails) => void;
}

const FlowSubmitScreen: React.FC<FlowSubmitScreenProps> = ({ showModal, handleClose, handleSubmit }) => {
  const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetails>({
    worker_name: '',
    scheduled_datetime: '',
    scheduled_timezone: '',
    recurrence: { frequency: 'none', interval: 1, days_of_week: [], days_of_month: [] }, // Ensure both arrays are initialized
  });

  // Handle toggling days of the week
  const handleToggleWeekDay = (day: number) => {
    const { recurrence } = scheduleDetails;
    const days_of_week = recurrence.days_of_week || [];
    const updatedWeekDays = days_of_week.includes(day)
      ? days_of_week.filter(d => d !== day)
      : [...days_of_week, day];
    setScheduleDetails({ ...scheduleDetails, recurrence: { ...recurrence, days_of_week: updatedWeekDays } });
  };

  // Handle toggling days of the month
  const handleToggleDayOfMonth = (day: number) => {
    const { recurrence } = scheduleDetails;
    const days_of_month = recurrence.days_of_month || [];
    const updatedDaysOfMonth = days_of_month.includes(day)
      ? days_of_month.filter(d => d !== day)
      : [...days_of_month, day];
    setScheduleDetails({ ...scheduleDetails, recurrence: { ...recurrence, days_of_month: updatedDaysOfMonth } });
  };

  const onSubmit = () => {
    handleSubmit(scheduleDetails);
    handleClose(); // Close the modal after submission
  };

  return (
    <Modal show={showModal} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Flow</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="worker_name">
            <Form.Label>Worker Name</Form.Label>
            <Form.Control
              type="text"
              value={scheduleDetails.worker_name}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, worker_name: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="scheduled_datetime">
            <Form.Label>Scheduled Datetime</Form.Label>
            <Form.Control
              type="datetime-local"
              value={scheduleDetails.scheduled_datetime}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_datetime: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="scheduled_timezone">
            <Form.Label>Timezone</Form.Label>
            <Form.Control
              type="text"
              value={scheduleDetails.scheduled_timezone}
              onChange={(e) => setScheduleDetails({ ...scheduleDetails, scheduled_timezone: e.target.value })}
            />
          </Form.Group>
          <Form.Group controlId="recurrence">
            <Form.Label>Recurrence</Form.Label>
            <Form.Control
              as="select"
              value={scheduleDetails.recurrence.frequency}
              onChange={(e) =>
                setScheduleDetails({
                  ...scheduleDetails,
                  recurrence: { ...scheduleDetails.recurrence, frequency: e.target.value }
                })
              }
            >
              <option value="none">None</option>
              <option value="day">Daily</option>
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
              <option value="year">Yearly</option>
            </Form.Control>
          </Form.Group>

          {/* Conditional rendering based on the recurrence type */}
          {scheduleDetails.recurrence.frequency === 'day' && (
            <Form.Group controlId="days_of_week">
              <Form.Label>Select Days of the Week</Form.Label>
              <Row>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
                  (dayName, index) => (
                    <Col key={index} xs={6}>
                      <Form.Check
                        type="checkbox"
                        label={dayName}
                        checked={!!scheduleDetails.recurrence.days_of_week?.includes(index + 1)} // Convert undefined to false
                        onChange={() => handleToggleWeekDay(index + 1)} // Days of week are 1-based
                      />
                    </Col>
                  )
                )}
              </Row>
            </Form.Group>
          )}

          {scheduleDetails.recurrence.frequency === 'month' && (
            <Form.Group controlId="days_of_month">
              <Form.Label>Select Days of the Month</Form.Label>
              <Row>
                {Array.from({ length: 31 }, (_, index) => index + 1).map(day => (
                  <Col key={day} xs={2}>
                    <Form.Check
                      type="checkbox"
                      label={String(day)}
                      checked={!!scheduleDetails.recurrence.days_of_month?.includes(day)} // Convert undefined to false
                      onChange={() => handleToggleDayOfMonth(day)}
                    />
                  </Col>
                ))}
              </Row>
            </Form.Group>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Schedule Flow
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FlowSubmitScreen;
