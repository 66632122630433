import { Dropdown } from 'react-bootstrap';

type ActionDropdownFlowsProps = {
  flow_instance_id: number;
  onAction: (flow_instance_id: number, action: string) => void;
};

const ActionDropdownFlowsInstances: React.FC<ActionDropdownFlowsProps> = ({ flow_instance_id, onAction }) => {
  return (
    <>
      {/* <Dropdown.Item eventKey="1" onClick={() => onAction(block_id, 'edit')}>Edit</Dropdown.Item> */}
      <Dropdown.Item eventKey="1" onClick={() => onAction(flow_instance_id, 'view')}>View</Dropdown.Item>
      {/* <Dropdown.Item eventKey="2" onClick={() => onAction(flow_id, 'edit_popout')}>Edit</Dropdown.Item> */}
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4" className="text-danger" onClick={() => onAction(flow_instance_id, 'remove')}>
        Remove
      </Dropdown.Item>
    </>
  );
};

export default ActionDropdownFlowsInstances;
