import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'

import api from 'services/api';

// import { Provider } from 'react-redux';
// import store from '../../../redux/store';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';

import {login} from '../../../redux/slices/authThunk'

import { fetchUserData } from '../../../redux/slices/authThunk';

const SignInForm = (props) => {
  const layout = "simple"
  const [username, setusername] = useState('')
  const [password, setPassword] = useState('')
  const [loggedIn, setLoggedIn] = useState(false)
  const [usernameError, setusernameError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate()

  const dispatch = useDispatch();
  const {token, loading} = useSelector((state: RootStateOrAny) => state.auth);

  // Call the server API to check if the given username ID already exists
// const checkAccountExists = (callback) => {
//     fetch('http://localhost:3080/check-account', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ username }),
//     })
//       .then((r) => r.json())
//       .then((r) => {
//         callback(r?.userExists)
//       })
//   }
  
  // Log in a user using username and password
  const value = useSelector((state: RootStateOrAny) => state.auth)
  useEffect(() => {
    
    console.log("state value: "+JSON.stringify(value))
    if(value.token !== 'undefined'){
      console.log("does exist")
      // navigate('/')
    }
  }, [value])


  const logIn = (e) => {
    e.preventDefault();
    
    dispatch(login({username, password}))

    navigate('/')

    // fetch('http://localhost:5002/users/login', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ username, password }),
    // })
    // .then((r) => r.json())
    //   .then((r) => {
    //     console.log("In .then loop")
    //     console.log(r);
    //     if (r.token) {
    //       localStorage.setItem('user', JSON.stringify({ username, token: 'Bearer '+r.token }))   
    //       localStorage.setItem('token',r.token)
    //       // dispatch(fetchUserData());
    //       api.defaults.headers.Authorization = `Bearer ${r.token}`;       
    //       navigate('/')
    //     } else {
    //       window.alert('Wrong username or password')
    //     }
    //   })
  }



  return (
    <>

      <div className="text-center mb-7">
        <h3 className="text-1000">Sign In</h3>
        <p className="text-700">Get access to your account</p>
      </div>
      {/*
      <AuthSocialButtons title="Sign in" />
      <div className="position-relative">
        <hr className="bg-200 mt-5 mb-4" />
        <div className="divider-content-center">or use email</div>
      </div>
      */}
      <Form onSubmit={logIn}>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">Username</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="username"
            className="form-icon-input"
            placeholder="johndoe"
            value ={username}
            onChange={(ev) => setusername(ev.target.value)}
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">Password</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              Remember me
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/authentication/${layout}/forgot-password`}
            className="fs-9 fw-semi-bold"
          >
            Forgot Password?
          </Link>
        </Col>
      </Row>
      <Button variant="primary" className="w-100 mb-3" type="submit">
        Sign In
      </Button>
      </Form>
      <div className="text-center">
        <Link
          to={`/pages/authentication/${layout}/sign-up`}
          className="fs-9 fw-bold"
        >
          Create an account
        </Link>
      </div>
    </>
  );
};

export default SignInForm;
