import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
// import { ButtonGroup } from "react-bootstrap";
// import { fetchDevices, deleteDevice } from "../../../../redux/slices/devicesThunk";
// import EditDevice from "./editDevice";
// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";
import ListDevices from "../devices/devices";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

const Config = () => {


    return (
        <div>
            <h1>Configuratie</h1>


            <Row xs={1} md={2} className="g-4">
        <Col key={1}>
  <Card>
    <Card.Body>
      <Card.Title>Apparaten</Card.Title>
      <Card.Text>
      <ListDevices />
      </Card.Text>
    </Card.Body>
  </Card>
</Col>
</Row>


            

        </div>
    );
};

export default Config;
