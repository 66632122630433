import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import { Line } from "react-chartjs-2";
import 'chartjs-adapter-date-fns';
import { Chart, registerables } from 'chart.js';
import { fetchDashboardPages, fetchDashboardItemData } from "../../../../redux/slices/dashboardThunk";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import moment from 'moment';

Chart.register(...registerables);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DashboardPageItem: React.FC = () => {
  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dashboard_page_id = query.get('dashboard_page_id');
  // const dashboard_item_id = query.get('dashboard_item_id');
  const start = query.get('start');
  const end = query.get('end') || new Date().toISOString();


  const dashboard = useSelector((state: any) => state.dashboard);
  const [chartData, setChartData] = useState<any>([]);

  const setStartDate = (daysAgo: number) => {
    const newStart = moment().subtract(daysAgo, 'days').toISOString();
    query.set('start', newStart);
    navigate({ search: query.toString() });
  };

  useEffect(() => {
    dispatch(fetchDashboardPages());
  }, [dispatch]);

  useEffect(() => {
    if (dashboard.pages.length > 0) {
      const page = dashboard.pages.find((p: any) => p.dashboard_page_id === Number(dashboard_page_id));
      if (page) {
        const allEntityIds = page.items.flatMap((item: any) =>
          item.dashboard_item_entities.map((entity: any) => entity.entity_id)
        );
        dispatch(fetchDashboardItemData({ entities: allEntityIds, start, end }));
      }
    }
  }, [location, dashboard.pages, dashboard_page_id, dispatch]);

  useEffect(() => {
    if (dashboard.itemData.length > 0) {
      const page = dashboard.pages.find((p: any) => p.dashboard_page_id === Number(dashboard_page_id));
      // const item = page.items.find((i: any) => i.dashboard_item_id === Number(dashboard_item_id));
      if (page) {
        const updatedChartData = page.items.map((item: any) => {
          const datasets = item.dashboard_item_entities.map((entity: any) => {
            const entityData = dashboard.itemData.filter((d: any) => d.entity_id === entity.entity_id);
            return {
              label: entity.entity_id,
              data: entityData.map((d: any) => ({ x: d._time, y: d._value })),
              borderColor: entity.color,
              fill: false,
              spanGaps: true, // Force lines between data points
              tension: 0.1
            };
          });

          // setChartData({
          //   datasets: datasets
          // });
          return {
            id: item.dashboard_item_id,
            title: item.dashboard_item_title,
            datasets
          };
        });
        setChartData(updatedChartData);
      }
    }
  }, [dashboard.itemData, dashboard_page_id, dashboard.pages]);

  if (dashboard.loading || !dashboard.pages.length) {
    return <div>Loading...</div>;
  }

  const page = dashboard.pages.find((p: any) => p.dashboard_page_id === Number(dashboard_page_id));
  if (!page) {
    return <div>Page not found</div>;
  }

  // const item = page.items.find((i: any) => i.dashboard_item_id === Number(dashboard_item_id));
  // if (!item) {
  //   return <div>Item not found</div>;
  // }

  return (
    <div>
      <h1>{page.dashboard_page_title}</h1>
      {page.dashboard_page_description && <p>{page.dashboard_page_description}</p>}

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '1rem' }}>
        <ButtonGroup aria-label="Basic example">
          <Button variant="phoenix-secondary" onClick={() => setStartDate(1)}>Last 24 Hours</Button>
          <Button variant="phoenix-secondary" onClick={() => setStartDate(2)}>Last 48 Hours</Button>
          <Button variant="phoenix-secondary" onClick={() => setStartDate(7)}>Last 7 Days</Button>
        </ButtonGroup>
      </div>

      {chartData.map((item: any) => (
        <div key={item.id} style={{ marginBottom: '2rem' }}>
          <h2>{item.title}</h2>
          <div style={{ width: '100%', height: '400px' }}>
            <Line data={{ datasets: item.datasets }} options={{
              scales: {
                x: {
                  type: 'time' as const,
                  time: {
                    tooltipFormat: 'dd-MM HH:mm',
                    displayFormats: {
                      minute: 'dd-MM HH:mm',
                      hour: 'dd-MM HH:mm',
                      day: 'dd-MM HH:mm'
                    }
                  },
                  title: {
                    display: true,
                    text: 'Time'
                  }
                },
                y: {
                  title: {
                    display: true,
                    text: 'Value (kW)'
                  }
                }
              },
              plugins: {
                legend: {
                  display: true,
                  position: 'top' as const,
                },
                tooltip: {
                  mode: 'index' as const,
                  intersect: false
                }
              },
              maintainAspectRatio: false
            }} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardPageItem;