import { createAsyncThunk } from '@reduxjs/toolkit'
// import {getToken, removeToken, setToken} from '../../utils/HelperFunctions';
import api from '../../services/api';
import history from '../../utils/history';
import { useNavigate } from 'react-router-dom'

export const fetchConfigGenerators = createAsyncThunk('config/fetchGenerators', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/config/generators');
        return response.data;
    } catch (e) {
        return rejectWithValue('');
    }
});


export const updateConfigGenerator = createAsyncThunk('config/updateGenerator', async (payload: object) => {
    const response = await api.put('/config/updateGenerator/' + payload['generator_id'], payload);
    return response.data;
});


export const deleteConfigGenerator = createAsyncThunk('config/deleteGenerator', async (payload: object) => {
    var response = await api.delete('/config/deleteGenerator/' + payload['generator_id']);
    const response2 = { "generator_id": payload['generator_id'], "status": response.data.status }
    return response2;
});



export const createConfigGenerator = createAsyncThunk('config/createGenerator', async (payload: object) => {
    const response = await api.post('/config/createGenerator/', payload);
    return response.data;
});




export const fetchContracts = createAsyncThunk('config/fetchContracts', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/config/fetchcontracts');
        return response.data;
    } catch (e) {
        return rejectWithValue('');
    }
});

export const fetchContractLovs = createAsyncThunk('config/fetchContractLovs', async (_, { rejectWithValue }) => {
    try {
        const response = await api.get('/config/fetchcontractlovs');
        return response.data;
    } catch (e) {
        return rejectWithValue('');
    }
});


export const updateContract = createAsyncThunk('config/updateContract', async (payload: object) => {
    const response = await api.put('/config/contract/' + payload['contract_id'], payload);
    return response.data;
});

// ###### DELETE CONTRACT 
export const deleteContract = createAsyncThunk('config/deleteContract', async (payload: object) => {
    var response = await api.delete('/config/contract/' + payload['contract_id']);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    const response2 = { "contract_id": payload['contract_id'], "status": response.data.status }
    // console.log("listenersthunk - payload: " + payload)
    return response2;
});