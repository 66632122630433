import { Dropdown } from 'react-bootstrap';

type ActionDropdownBlocksProps = {
  block_id: number;
  onAction: (block_id: number, action: string) => void;
};

const ActionDropdownBlocks: React.FC<ActionDropdownBlocksProps> = ({ block_id, onAction }) => {
  return (
    <>
      {/* <Dropdown.Item eventKey="1" onClick={() => onAction(block_id, 'edit')}>Edit</Dropdown.Item> */}
      <Dropdown.Item eventKey="2" onClick={() => onAction(block_id, 'edit_popout')}>Edit</Dropdown.Item>
      <Dropdown.Divider />
      <Dropdown.Item eventKey="4" className="text-danger" onClick={() => onAction(block_id, 'remove')}>
        Remove
      </Dropdown.Item>
    </>
  );
};

export default ActionDropdownBlocks;
