import React, { Fragment, useEffect, useState } from "react";

import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownItems from 'components/common/ActionDropdownItems';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";

import EditUser from "components/nors/editUser";
import UserContainer from "components/nors/UserContainer";

import { getBackendUrl } from '../../../services/backendUrl'; // Import getBackendUrl

const ListUsers = () => {
    const [users, setUsers] = useState<any[]>([]);

    // Delete a user
    const deleteUser = async (id) => {
        try {
            const baseURL = await getBackendUrl(); // Fetch the backend URL dynamically
            await fetch(baseURL + '/users/' + id, {
                method: 'DELETE'
            });
            setUsers(users.filter(user => user.user_id !== id));
        } catch (error) {
            console.error(error);
        }
    };

    const getUsers = async () => {
        try {
            const baseURL = await getBackendUrl(); // Fetch the backend URL dynamically
            const user = JSON.parse(localStorage.getItem('user'));
            console.log("Files fetch user: " + JSON.stringify(user));
            const response = await fetch(baseURL + '/users', {
                method: 'GET',
                headers: {
                    'Authorization': user.token,
                },
            });
            const jsonData = await response.json();
            console.log("test log console");
            setUsers(jsonData);
        } catch (err) {
            console.error(JSON.stringify(err));
        }
    };

    useEffect(() => {
        getUsers();
        console.log("From useEffect function. Type: " + typeof users);
        console.log(users);
    }, []);

    return (
        <div>
            <UserContainer />
            <br />
            {/* <Button variant="phoenix-primary" startIcon={<FontAwesomeIcon icon={faPlus} />}>
                Create
            </Button> */}
        </div>
    );
};

export default ListUsers;
