import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
// import { ButtonGroup } from "react-bootstrap";
// import { fetchDevices, deleteDevice } from "../../../../redux/slices/devicesThunk";
// import EditDevice from "./editDevice";
// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";
import ListDevices from "../devices/devices";
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';

import EditContract from "./editContractAndConnection";
import { ButtonGroup } from "react-bootstrap";
import { deleteContract, fetchContractLovs, fetchContracts } from "../../../../redux/slices/configThunk";
import FeatherIcon from 'feather-icons-react';

const ContractAndConnection = () => {


    const dispatch = useDispatch();
    const contracts = useSelector((state: RootStateOrAny) => state.config.contracts)
    const configLoading = useSelector((state: RootStateOrAny) => state.config.contracts_loading)
    useEffect(() => {
        // getFiles();
        dispatch(fetchContractLovs());
    }, []);
    useEffect(() => {
        // getFiles();
        dispatch(fetchContracts());
    }, []);


    const handleDelete = async (contract_id) => {
        // e.preventDefault();
        try {

          // const body = { username, password, home_folder, mail }
          const body = {contract_id}
          console.log(body)
          console.log("handleDelete: "+contract_id)
          // const payload = { user_id }
          dispatch(deleteContract(body))
        } catch (error) {
          console.error(error.message)
        }
      }


    return (
        <div>
            <h1>Contracten en Aansluitingen</h1>


            <Table hover size='sm' >
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Contract Type</th>
                        <th>Aansluiting</th>
                        <th>Actie</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(contracts).map(([key, contract], i) => (
                        <tr key={i}>
                            <td>{contracts[i].contract_name}</td>
                            <td>{contracts[i].contract_type_name}</td>
                            <td>{contracts[i].connection_type_name}</td>
                            <td>
                            <ButtonGroup aria-label="Basic example">
                                <EditContract stateId={i} />
                                <Button variant="phoenix-secondary" onClick={() => handleDelete(contracts[i].contract_id)}>Delete</Button>
                            </ButtonGroup>
                                </td>
                        </tr>
                    ))}
                </tbody>
            </Table>


            

            <span>
            <FeatherIcon icon='info' size='16'/> Als u meer dan 1 meetpunt heeft, kunt u hier meerdere contracten toevoegen aangeven. Een extra meetpunt is een extra elektriciteitsmeter die achter een hoofdaansluiting is geplaatst. U kunt daarvoor ook een andere energieleverancier gecontracteerd hebben met verschillende contractvoorwaarden.
            </span>


        </div>
    );
};

export default ContractAndConnection;
