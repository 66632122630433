import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

interface Worker {
    worker_id: number;
    worker_name: string;
}

interface WorkerState {
    items: Worker[];
    status: string | null;
}

const initialState: WorkerState = {
    items: [],
    status: null,
};

export const fetchWorkers = createAsyncThunk('workers/fetchWorkers', async () => {
    const response = await api.get('/workers');
    return response.data;
});

const workersSlice = createSlice({
    name: 'workers',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchWorkers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWorkers.fulfilled, (state, action) => {
                state.items = action.payload;
                state.status = 'succeeded';
            })
            .addCase(fetchWorkers.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export default workersSlice.reducer;
