import { createSlice } from '@reduxjs/toolkit'
import { listTotalEnergy } from './influxThunk';

const initialState = {
    influx: [],
    loading: false
};

export const influxSlice = createSlice({
    name: 'influx',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // Fetch Devices
        .addCase(listTotalEnergy.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(listTotalEnergy.fulfilled, (state, action) => {
            const influx = action.payload;
            state.influx = influx;
            state.loading = false;
        })
        .addCase(listTotalEnergy.rejected, (state, action) => {
            state.loading = false;
            state.influx = [];
        })

    },
})

export const {} = influxSlice.actions;

export default influxSlice.reducer;
