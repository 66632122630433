import React, { useEffect } from "react";
import { Provider } from 'react-redux';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { fetchDevices, deleteDevice } from "../../../../redux/slices/devicesThunk";


import HassEntity from './types/HassEntity';
import HassInputNumber from './types/HassInputNumber';
import HassProvider from './HassProvider';
import HassInputSelect from "./types/HassInputSelect";

// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";


const Ems_contract = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state: RootStateOrAny) => state.devices.devices);
    const devicesLoading = useSelector((state: RootStateOrAny) => state.devices.loading);

    useEffect(() => {
        dispatch(fetchDevices());
    }, [dispatch]);

    const handleDelete = async (deviceId) => {
        try {
            dispatch(deleteDevice({ device_id: deviceId }));
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            <h2>Contract en Aansluiting</h2>
            <br />

            

   


            <HassProvider>
                <Table hover size='sm'>
                    <thead>
                        <th>Variabele</th>
                        <th>Waarde</th>
                        <th>Actie</th>
                    </thead>
                    <tbody>
                    <HassInputSelect entityId="input_select.ems_contract_type" />
                    <HassInputSelect entityId="input_select.ems_number_of_phases" />
                    <HassInputNumber entityId="input_number.ems_amp_per_phase" />
                    <HassInputNumber entityId="input_number.ems_normal_own_usage" />
                    <HassInputNumber entityId="input_number.ems_vast_contract_inkoopvergoeding" />
                    <HassInputNumber entityId="input_number.ems_vast_contract_verkoopvergoeding" />
                    
                    </tbody>
                </Table>
            {/* <HassEntity entityId="input_boolean.ems_auto_switch_battery_state" /> */}
            
            </HassProvider>


            
        </div>
    );
};

export default Ems_contract;
