import { createSlice } from '@reduxjs/toolkit'
import { fetchRoles, updateRole, deleteRole, createRole } from './rolesThunk';

const initialState = {
    roles: [],
    loading: false
};

export const rolesSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // .addCase(signOut.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.userData = {};
        //     state.token = null;
        // })
        // .addCase(login.pending, (state, action) => {
        //     state.loading = true;
        //     state.token = null;
        // })
        // .addCase(login.fulfilled, (state, action) => {
        //     const {token} = action.payload;
        //     state.token = token;
        //     // state.userData = user;
        //     state.loading = false;
        // })
        // .addCase(login.rejected, (state, action) => {
        //     state.loading = false;
        // })




        // ###### FETCH ROLES
        .addCase(fetchRoles.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchRoles.fulfilled, (state, action) => {
            const roles = action.payload;
            state.roles = roles;
            // state.userData = user;
            state.loading = false;
        })
        .addCase(fetchRoles.rejected, (state, action) => {
            state.loading = false;
            state.roles = [];
        })



        // ###### CREATE Role
        .addCase(createRole.pending, (state, action) => {
            state.createRoleLoading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(createRole.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            // const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.roles = [...state.roles, action.payload];
            state.createRoleLoading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(createRole.rejected, (state, action) => {
            state.createRoleLoading = false;
        })



        // ###### UPDATE ROLE
        .addCase(updateRole.pending, (state, action) => {
            state.updateRoleLoading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(updateRole.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            const index = state.roles.findIndex((e) => e.role_id === action.payload.role_id)
            state.roles[index] = action.payload
            state.updateRoleLoading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(updateRole.rejected, (state, action) => {
            state.updateRoleLoading = false;
        })



        // ###### DELETE ROLE
        .addCase(deleteRole.pending, (state, action) => {
            state.deleteRoleLoading = true;
            // state.listeners[action.stateId] = {};
            // state.token = null;
        })
        .addCase(deleteRole.fulfilled, (state, action) => {
            // const {token} = action.payload;
            // state.token = token;
            // state.userData = user;
            // const index = state.listeners.findIndex((e) => e.listener_id === action.payload.listener_id)
            state.roles = state.roles.filter(role => action.payload.role_id !== role.role_id)
            // users: state.users.filter(user => action.payload !== user.user_id)
            state.deleteRoleLoading = false;
            // state.listeners[action.stateId] = action.payload;
        })
        .addCase(deleteRole.rejected, (state, action) => {
            state.deleteRoleLoading = false;
        })



    },
})


export const {} = rolesSlice.actions;

export default rolesSlice.reducer;