import React, { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { fetchDevices, deleteDevice } from "../../../../redux/slices/devicesThunk";
import EditDevice from "./editDevice";
// import CreateDevice from "./createDevice";
// import EditDevice from "./editDevice";


const ListDevices = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state: RootStateOrAny) => state.devices.devices);
    const devicesLoading = useSelector((state: RootStateOrAny) => state.devices.loading);

    useEffect(() => {
        dispatch(fetchDevices());
    }, [dispatch]);

    const handleDelete = async (deviceId) => {
        try {
            dispatch(deleteDevice({ device_id: deviceId }));
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            {/* <h2>Devices</h2> */}

            <Table hover size='sm'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Device Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {devices.map((device, index) => (
                        <tr key={device.device_id}>
                            <td>{device.device_id}</td>
                            <td>{device.device_name}</td>
                            <td>
                                <ButtonGroup aria-label="Basic example">
                                    <EditDevice device={device} />
                                    <Button variant="phoenix-secondary" onClick={() => handleDelete(device.device_id)}>Delete</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* <CreateDevice /> */}
        </div>
    );
};

export default ListDevices;
