import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSAPConnections, createSAPConnection, updateSAPConnection, deleteSAPConnection } from '../../../../redux/slices/connectionSAPSlice';
import { fetchWorkers } from '../../../../redux/slices/workersSlice';
import { useNavigate } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, { RevealDropdownTrigger } from 'components/base/RevealDropdown';
import ActionDropdownConnections from '../../../../components/common/ActionDropdownConnections';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import SearchBox from 'components/common/SearchBox';

interface Worker {
    worker_id: number;
    worker_name: string;
    priority?: number;
}

interface SAPConnection {
    connections_sap_id: number;
    connection_sap_name: string;
    sap_host: string;
    sap_sysnr: string;
    sap_client: string;
    sap_user: string;
    sap_password: string;
    language: string;
    maximumnumberofrfcconnections: number;
    workers: Worker[];
}

const ListSAPConnections = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const connections = useSelector((state: any) => state.sapConnections.items);
    const workers = useSelector((state: any) => state.workers.items); // Fetch workers from the Redux store
    const loading = useSelector((state: any) => state.sapConnections.loading);

    const [showModal, setShowModal] = useState(false);
    const [currentSAPConnection, setCurrentSAPConnection] = useState<SAPConnection | null>(null);
    const [connectionData, setConnectionData] = useState<Omit<SAPConnection, 'connections_sap_id'>>({
        connection_sap_name: '',
        sap_host: '',
        sap_sysnr: '',
        sap_client: '',
        sap_user: '',
        sap_password: '',
        language: '',
        maximumnumberofrfcconnections: 0,
        workers: [],
    });

    useEffect(() => {
        dispatch(fetchSAPConnections());
        dispatch(fetchWorkers());
    }, [dispatch]);

    const handleAction = async (connections_sap_id: number, action: string) => {
        const connection = connections.find((connection: SAPConnection) => connection.connections_sap_id === connections_sap_id);
        if (action === 'remove') {
            dispatch(deleteSAPConnection(connections_sap_id));
        } else if (action === 'edit') {
            setCurrentSAPConnection(connection);
            if (connection) {
                setConnectionData({ ...connection });
            } else {
                setConnectionData({
                    connection_sap_name: '',
                    sap_host: '',
                    sap_sysnr: '',
                    sap_client: '',
                    sap_user: '',
                    sap_password: '',
                    language: '',
                    maximumnumberofrfcconnections: 0,
                    workers: [],
                });
            }
            setShowModal(true);
        }
    };

    const columns: ColumnDef<SAPConnection>[] = [
        {
            accessorKey: 'connection_sap_name',
            header: 'Connection Name',
            cell: ({ row }) => (
                <span
                    onClick={() => handleAction(row.original.connections_sap_id, 'view')}
                    style={{ cursor: 'pointer', color: '#007bff' }}
                >
                    {row.original.connection_sap_name}
                </span>
            ),
        },
        {
            id: 'action',
            cell: ({ row }) => (
                <RevealDropdownTrigger>
                    <RevealDropdown>
                        <ActionDropdownConnections connection_id={row.original.connections_sap_id} onAction={handleAction} />
                    </RevealDropdown>
                </RevealDropdownTrigger>
            ),
            meta: {
                headerProps: { style: { width: '7%' } },
                cellProps: { className: 'text-end' },
            },
        },
    ];

    const table = useAdvanceTable({
        data: connections,
        columns,
        pageSize: 20,
        pagination: true,
        selection: true,
        sortable: true,
    });

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        table.setGlobalFilter(e.target.value || undefined);
    };

    const handleNewSAPConnectionClick = () => {
        setCurrentSAPConnection(null);
        setConnectionData({
            connection_sap_name: '',
            sap_host: '',
            sap_sysnr: '',
            sap_client: '',
            sap_user: '',
            sap_password: '',
            language: '',
            maximumnumberofrfcconnections: 0,
            workers: [],
        });
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleSave = () => {
        const updatedData = { ...connectionData };
        if (currentSAPConnection) {
            dispatch(updateSAPConnection({ ...currentSAPConnection, ...updatedData, connections_sap_id: currentSAPConnection.connections_sap_id }));
        } else {
            dispatch(createSAPConnection(updatedData));
        }
        handleCloseModal();
    };

    return (
        <Container fluid className="p-0">
            <Row className="m-0 mb-4">
                <Col className="p-0 d-flex justify-content-between align-items-center">
                    <SearchBox
                        placeholder="Search..."
                        size="sm"
                        onChange={handleSearchInputChange}
                        className="mx-auto"
                    />
                    <Button variant="primary" size="sm" onClick={handleNewSAPConnectionClick}>
                        New Connection
                    </Button>
                </Col>
            </Row>
            <Row className="m-0">
                <Col className="p-0">
                    <AdvanceTableProvider {...table}>
                        <AdvanceTable
                            tableProps={{
                                size: 'sm',
                                className: 'phoenix-table fs-9 mb-0 border-top border-translucent w-100',
                            }}
                            rowClassName="hover-actions-trigger btn-reveal-trigger position-static"
                        />
                        <AdvanceTableFooter navBtn />
                    </AdvanceTableProvider>
                </Col>
            </Row>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentSAPConnection ? 'Edit Connection' : 'New Connection'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="connection_sap_name">
                            <Form.Label>Connection Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.connection_sap_name}
                                onChange={(e) => setConnectionData({ ...connectionData, connection_sap_name: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="sap_host">
                            <Form.Label>SAP Host</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.sap_host}
                                onChange={(e) => setConnectionData({ ...connectionData, sap_host: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="sap_sysnr">
                            <Form.Label>SAP Sysnr</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.sap_sysnr}
                                onChange={(e) => setConnectionData({ ...connectionData, sap_sysnr: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="sap_client">
                            <Form.Label>SAP Client</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.sap_client}
                                onChange={(e) => setConnectionData({ ...connectionData, sap_client: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="sap_user">
                            <Form.Label>SAP User</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.sap_user}
                                onChange={(e) => setConnectionData({ ...connectionData, sap_user: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="sap_password">
                            <Form.Label>SAP Password</Form.Label>
                            <Form.Control
                                type="password"
                                value={connectionData.sap_password}
                                onChange={(e) => setConnectionData({ ...connectionData, sap_password: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="language">
                            <Form.Label>Language</Form.Label>
                            <Form.Control
                                type="text"
                                value={connectionData.language}
                                onChange={(e) => setConnectionData({ ...connectionData, language: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group controlId="maximumnumberofrfcconnections">
                            <Form.Label>Maximum Number of RFC Connections</Form.Label>
                            <Form.Control
                                type="number"
                                value={connectionData.maximumnumberofrfcconnections}
                                onChange={(e) => setConnectionData({ ...connectionData, maximumnumberofrfcconnections: parseInt(e.target.value, 10) })}
                            />
                        </Form.Group>
                        <Form.Group controlId="workers">
                            <Form.Label>Workers</Form.Label>
                            {workers.map((worker: Worker) => (
                                <div key={worker.worker_id} className="mb-2">
                                    <Form.Check
                                        type="checkbox"
                                        label={worker.worker_name}
                                        checked={connectionData.workers?.some((w) => w.worker_id === worker.worker_id) || false}
                                        onChange={(e) => {
                                            const updatedWorkers = e.target.checked
                                                ? [...(connectionData.workers || []), { ...worker, priority: 0 }]
                                                : (connectionData.workers || []).filter((w) => w.worker_id !== worker.worker_id);
                                            setConnectionData({ ...connectionData, workers: updatedWorkers });
                                        }}
                                    />
                                    {connectionData.workers?.some((w) => w.worker_id === worker.worker_id) && (
                                        <Form.Control
                                            type="number"
                                            value={
                                                connectionData.workers.find((w) => w.worker_id === worker.worker_id)?.priority
                                            }
                                            onChange={(e) => {
                                                const updatedWorkers = (connectionData.workers || []).map((w) =>
                                                    w.worker_id === worker.worker_id
                                                        ? { ...w, priority: parseInt(e.target.value, 10) }
                                                        : w
                                                );
                                                setConnectionData({ ...connectionData, workers: updatedWorkers });
                                            }}
                                            placeholder="Priority"
                                        />
                                    )}
                                </div>
                            ))}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ListSAPConnections;
