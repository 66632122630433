import { Stack } from 'react-bootstrap';
import starterImg from 'assets/img/spot-illustrations/2.png';
import starterDarkImg from 'assets/img/spot-illustrations/dark_2.png';
import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
import logo from 'assets/img/icons/LogoConnectivityPlatform.png';

import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import CardDeck from 'react-bootstrap'
// import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Deck } from 'components/cards/CusDeck';



const NcpDescription = () => {
  return (
    // <Stack className="flex-center content-min-h">
    <><div className='text-center'>



      <img style={{ maxWidth: '572px', width: '100%' }} src={logo} alt={process.env.REACT_APP_COMPANY_NAME} />
    </div>
      <div>
        
        <Row xs={1} md={2} className="g-4">
  <Col key={1}>
    <Card>
      <Card.Body>
        <Card.Title>Real-time Monitoring</Card.Title>
        <Card.Text>
          This integration and automation platform offers real-time monitoring capabilities, allowing users to track workflow performance and instantly identify areas for optimization, resulting in improved efficiency and cost savings.
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
  <Col key={2}>
    <Card>
      <Card.Body>
        <Card.Title>Predictive Analysis</Card.Title>
        <Card.Text>
          Utilizing advanced predictive analytics, this platform forecasts workflow trends and system demands, enabling proactive decision-making and preventive maintenance strategies, ultimately reducing downtime and operational costs.
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
  <Col key={3}>
    <Card>
      <Card.Body>
        <Card.Title>IoT Integration</Card.Title>
        <Card.Text>
          Seamlessly integrating with Internet of Things (IoT) devices, this platform optimizes automation by collecting data from sensors and smart devices, allowing for precise control and automation of integrated systems for maximum efficiency.
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
  <Col key={4}>
    <Card>
      <Card.Body>
        <Card.Title>Automation Optimization Algorithms</Card.Title>
        <Card.Text>
          Equipped with advanced automation optimization algorithms, this platform dynamically adjusts workflow processes based on real-time data analysis, ensuring optimal performance while minimizing waste and inefficiencies.
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
  <Col key={5}>
    <Card>
      <Card.Body>
        <Card.Title>User Interface</Card.Title>
        <Card.Text>
          Featuring an intuitive user interface, this platform provides users with actionable insights and control over automated workflows, allowing them to effortlessly make informed decisions and implement process improvements, fostering a culture of efficiency and innovation.
        </Card.Text>
      </Card.Body>
    </Card>
  </Col>
</Row>









      </div>
    </>
    // </Stack>

  );

};

export default NcpDescription;
