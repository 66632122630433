import { createAsyncThunk } from '@reduxjs/toolkit'
// import {getToken, removeToken, setToken} from '../../utils/HelperFunctions';
import api from '../../services/api';
import history from '../../utils/history';
import { useNavigate } from 'react-router-dom'

export const fetchListeners = createAsyncThunk('listeners/fetchListeners', async (_, { rejectWithValue }) => {
    try {
        // const accessToken = getToken();
        // console.log("redux accesstoken: "+accessToken)
        // api.defaults.headers.Authorization = `Bearer ${accessToken}`;
        const response = await api.get('/listeners');
        return response.data;
    } catch (e) {
        // removeToken();
        return rejectWithValue('');
    }
});

export const updateListener = createAsyncThunk('listeners/updateListener', async (payload: object) => {

    const response = await api.put('/listeners/' + payload['listener_id'], payload);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    return response.data;
});

export const deleteListener = createAsyncThunk('listeners/deleteListener', async (payload: object) => {
    var response = await api.delete('/listeners/' + payload['listener_id']);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    const response2 = { "listener_id": payload['listener_id'], "status": response.data.status }
    // console.log("listenersthunk - payload: " + payload)
    return response2;
});


export const startListener = createAsyncThunk('listeners/startListener', async (payload: object) => {

    var response = await api.get('/listeners/' + payload['listener_id'] + '/start');
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    const response2 = { "listener_id": payload['listener_id'], "response": response.data }
    console.log("listenersthunk - payload: " + payload)
    return response2;
});

export const createListener = createAsyncThunk('listeners/createListener', async (payload: object) => {

    const response = await api.post('/listeners/', payload);
    // setToken(response.data.token);
    // console.log("listenersThunk - updateListener payload: " + payload)
    // history.push('/');
    // const response = {data: "TEMP REMOVE"}
    return response.data;
});



// export const login = createAsyncThunk('auth/login', async (payload: object) => {
//     const response = await api.post('/users/login', payload);
//     setToken(response.data.token);

//     // history.push('/');

//     return response.data;
// });

// export const signOut = createAsyncThunk('auth/signOut', async () => {
//     removeToken();
//     // legacy:
//     localStorage.removeItem('user')
// });

