import { createSlice } from '@reduxjs/toolkit'
import { fetchUserfolders } from './userfoldersThunk';
const initialState = {
    userfolders: [],
    loading: false
};

export const userfoldersSlice = createSlice({
    name: 'userfolders',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // .addCase(signOut.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.userData = {};
        //     state.token = null;
        // })
        // .addCase(login.pending, (state, action) => {
        //     state.loading = true;
        //     state.token = null;
        // })
        // .addCase(login.fulfilled, (state, action) => {
        //     const {token} = action.payload;
        //     state.token = token;
        //     // state.userData = user;
        //     state.loading = false;
        // })
        // .addCase(login.rejected, (state, action) => {
        //     state.loading = false;
        // })




        // ###### FETCH LISTENER
        .addCase(fetchUserfolders.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchUserfolders.fulfilled, (state, action) => {
            const userfolders = action.payload;
            state.userfolders = userfolders;
            // state.userData = user;
            state.loading = false;
        })
        .addCase(fetchUserfolders.rejected, (state, action) => {
            state.loading = false;
            state.userfolders = [];
        })






    },
})


export const {} = userfoldersSlice.actions;

export default userfoldersSlice.reducer;