import { createSlice } from '@reduxjs/toolkit'
import {fetchFiles } from './filesThunk';

const initialState = {
    files: [],
    loading: false
};

export const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        // .addCase(signOut.fulfilled, (state, action) => {
        //     state.loading = false;
        //     state.userData = {};
        //     state.token = null;
        // })
        // .addCase(login.pending, (state, action) => {
        //     state.loading = true;
        //     state.token = null;
        // })
        // .addCase(login.fulfilled, (state, action) => {
        //     const {token} = action.payload;
        //     state.token = token;
        //     // state.userData = user;
        //     state.loading = false;
        // })
        // .addCase(login.rejected, (state, action) => {
        //     state.loading = false;
        // })
        .addCase(fetchFiles.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(fetchFiles.fulfilled, (state, action) => {
            const files = action.payload;
            state.files = files;
            // state.userData = user;
            state.loading = false;
        })
        .addCase(fetchFiles.rejected, (state, action) => {
            state.loading = false;
            state.files = [];
        })
    },
})


export const {} = filesSlice.actions;

export default filesSlice.reducer;