// store.js
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';
import rootSaga from './sagas'; // Import the root saga
import { composeWithDevTools } from '@redux-devtools/extension';

// Create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// Configure the store
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({ thunk: true })
            .concat(sagaMiddleware)
            .concat(logger),
    devTools: composeWithDevTools,
});

// Run the root saga
sagaMiddleware.run(rootSaga);

// Make the store globally accessible
window.sharedStore = store;

export default store;
