import { useState } from 'react';
// import Button from 'react-bootstrap/Button';
import Button from 'components/base/Button';
import Modal from 'react-bootstrap/Modal';
import { Fragment } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons'

// import PasswordChecklist from "react-password-checklist"


import { connect } from 'react-redux'
import { updateContract } from '../../../../redux/slices/configThunk';
import { useEffect } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { fetchContractLovs } from "../../../../redux/slices/configThunk";

const EditContract = (props) => {
    const dispatch = useDispatch();
    console.log("props: " + JSON.stringify(props))
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShowSmallConnectionDetails(defaultConnectionType)
                                setShow(true)

                                };

    const contract_id = useSelector((state: RootStateOrAny) => state.config.contracts[props.stateId].contract_id);
    //   const status = useSelector((state: RootStateOrAny) => state.listeners.listeners[props.stateId].status);
    const [contract_name, setContractName] = useState<string>()
    const [contract_type_id, setContract_type_id] = useState<string>()
    const [connection_type_id, setConnection_type_id] = useState<string>()
    //   const [port, setPort] = useState<string>()
    //   const [listener_name, setlistener_name] = useState<string>()
    //   const [auto_start, setAuto_start] = useState<boolean>()

    const contractTypes = useSelector((state: RootStateOrAny) => state.config.contractLovs.contract_types)
    const connectionTypes = useSelector((state: RootStateOrAny) => state.config.contractLovs.connection_types)
    const defaultConnectionType = useSelector((state: RootStateOrAny) => state.config.contracts[props.stateId].connection_type_id)
    const [showSmallConnectionDetails, setShowSmallConnectionDetails] = useState<boolean>();

    console.log("contract types: " + JSON.stringify(contractTypes))




    const handleUpdateContract = async (e) => {

        // console.log("editListener - e: " + JSON.stringify(e))
        try {
            e.preventDefault();
            const body = { contract_id, contract_name, contract_type_id, connection_type_id }
            console.log("editContract - body: " + JSON.stringify(body))
            dispatch(updateContract(body));
            // props.updateUser(body)
            // console.log(body)
        } catch (error) {
            console.error(error.message)
        }
    }



    const handleSetConnection_type_id = async (value) => {
        setConnection_type_id(value)
    }

    async function checkShowSmallConnectiontypes() {
        const result = true;
        return result
    }

    useEffect(() => {
        console.log("TEST456 connection type id: " + connection_type_id + typeof(connection_type_id))
        console.log("TEST456 show showSmallConnectionDetails: "+showSmallConnectionDetails)
        console.log("TEST456 defaultConnectionType: "+defaultConnectionType)
        if(connection_type_id !== undefined){
            setShowSmallConnectionDetails(connection_type_id === '1')
        } else {
            setShowSmallConnectionDetails(defaultConnectionType === '1')
        }
        
    },[connection_type_id])


    return (

        <>
            <Button variant="phoenix-secondary" onClick={handleShow}>
                Edit
            </Button>

            <Modal show={show} fullscreen={"md-down"} onHide={handleClose} backdrop="static" keyboard={false} size="xl">
                <Modal.Header>
                    <Modal.Title>Edit Contract</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form.Group as={Row} className="mb-3" controlId="formName">
                        <Form.Label column sm="2">
                            Name
                        </Form.Label>
                        <Col sm="10">
                            <Form.Control type="text" placeholder="Normal text" defaultValue={useSelector((state: RootStateOrAny) => state.config.contracts[props.stateId].contract_name)} onChange={ev => setContractName(ev.target.value)} />
                        </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3" controlId="formContractType">
                        <Form.Label column sm="2">
                            Contract type
                        </Form.Label>
                        <Col sm="10">
                            <Form.Select aria-label="Contract Type" defaultValue={useSelector((state: RootStateOrAny) => state.config.contracts[props.stateId].contract_type_id)} onChange={ev => setContract_type_id(ev.target.value)}>
                                {Object.entries(useSelector((state: RootStateOrAny) => state.config.contractLovs.contract_types)).map(([], i) => (


                                    <option key={i} value={contractTypes[i].contract_type_id}>{contractTypes[i].contract_type_name}</option>


                                ))}
                            </Form.Select>
                        </Col>
                    </Form.Group>


                    <Form.Group as={Row} className="mb-3" controlId="formConnectionType">
                        <Form.Label column sm="2">
                            Aansluiting
                        </Form.Label>
                        <Col sm="10">
                            <Form.Select aria-label="Connection Type" defaultValue={useSelector((state: RootStateOrAny) => state.config.contracts[props.stateId].connection_type_id)} onChange={ev => handleSetConnection_type_id(ev.target.value)}>
                                {Object.entries(useSelector((state: RootStateOrAny) => state.config.contractLovs.connection_types)).map(([], i) => (


                                    <option key={i} value={connectionTypes[i].connection_type_id}>{connectionTypes[i].connection_type_name}</option>


                                ))}
                            </Form.Select>
                        </Col>
                    </Form.Group>

                    
                    {showSmallConnectionDetails ?
                 <>test</>
                    : <>hidden</>}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="phoenix-danger" onClick={handleClose} startIcon={<FontAwesomeIcon icon={faWindowClose} />}>
                        Close
                    </Button>
                    <Button variant="phoenix-primary" onClick={(e) => handleUpdateContract(e)} startIcon={<FontAwesomeIcon icon={faSave} />}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

// export default EditUser;




// const mapStateToProps = (state, ownProps) => {
//   const user_id = ownProps.user_id
//   return {
//     userItem: state.user.users.find(item => item.user_id === user_id)
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     updateUser: user => dispatch(updateUser(user))
//   }
// }


// export default connect(mapStateToProps,mapDispatchToProps)(EditUser)

export default EditContract