import React, {useEffect, Fragment} from 'react'
import { connect } from 'react-redux'
import { fetchUsers, deleteUser } from '../../redux'

import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";

import EditUser from "./editUser";
import CreateUser from "./createUser";



const UserContainer = ({userData, fetchUsers, deleteUser}) => {

  useEffect(() => {
    fetchUsers()
  },[]) 


  const handleDelete = async (user_id) => {
    // e.preventDefault();
    try {
      // const body = { username, password, home_folder, mail }
      // console.log(body)
      console.log("handleDelete: "+user_id)
      // const payload = { user_id }
      deleteUser(user_id)
    } catch (error) {
      console.error(error.message)
    }
  }

  return userData.loading ? (
    <>
    <h1>Loading</h1></>
  ) : userData.error ? (
    <><h1>{userData.error}</h1></>
  ) : (
    <><div>
      <h1>Users</h1>




      <Table bordered hover size='sm' >
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Username</th>
                        <th>Home Folder ID</th>
                        <th>E-mail Address</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {userData && userData.users && userData.users.map(useritem =>
                        <tr key={useritem.user_id}>
                            <td>{useritem.user_id}</td>
                            <td>{useritem.username}</td>
                            <td>{useritem.home_folder}</td>
                            <td>{useritem.mail}</td>
                            <td>
                            <ButtonGroup aria-label="Basic example">
                                <EditUser user_id={useritem.user_id} />
                                <Button variant="phoenix-secondary" onClick={() => handleDelete(useritem.user_id)}>Delete</Button>
                            </ButtonGroup>
                            </td>
                        </tr>
                    )}
                </tbody>
                
                
            </Table>

<CreateUser user_id="new"/>


    </div></>
  )
    
}

const mapStateToProps = state => {
  return {
    userData: state.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchUsers: () => dispatch(fetchUsers()),
    deleteUser: (user_id) => dispatch(deleteUser(user_id))
  }
};

export default connect(mapStateToProps,mapDispatchToProps)(UserContainer)



