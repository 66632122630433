// backendUrl.js

export const getBackendUrl = async () => {
    try {
      const response = await fetch('/config.json');
      if (!response.ok) {
        throw new Error('Failed to load config.json');
      }
      const config = await response.json();
      return config.REACT_APP_BACKEND_URL;
    } catch (error) {
      console.error('Error loading backend URL from config.json:', error);
      // Fallback to the dynamic URL generation if config.json is not available or fails to load
      const frontendDomain = window.location.hostname;
      return `https://api.${frontendDomain}`;
    }
  };
  