import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../services/api';

// Thunks for async operations
export const fetchBlocks = createAsyncThunk('blocks/fetchBlocks', async () => {
    const response = await api.get('/blocks');
    return response.data;
});

export const fetchBlock = createAsyncThunk('blocks/fetchBlock', async (block: any) => {
    const response = await api.get(`/blocks/${block.block_id}`);
    return response.data;
});


export const createBlock = createAsyncThunk('blocks/createBlock', async (block: any) => {
    const response = await api.post('/blocks', block);
    return response.data;
});

export const updateBlock = createAsyncThunk('blocks/updateBlock', async (block: any) => {
    const response = await api.put(`/blocks/${block.block_id}`, block);
    return response.data;
});

export const deleteBlock = createAsyncThunk('blocks/deleteBlock', async (block_id: number) => {
    await api.delete(`/blocks/${block_id}`);
    return block_id;
});

const initialState = {
    blocks: [],
    loading: false,
    error: null,
};

const blocksSlice = createSlice({
    name: 'blocks',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // Fetch Blocks
            .addCase(fetchBlocks.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBlocks.fulfilled, (state, action) => {
                state.blocks = action.payload;
                state.loading = false;
            })
            .addCase(fetchBlocks.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Fetch Block
            .addCase(fetchBlock.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchBlock.fulfilled, (state, action) => {
                const index = state.blocks.findIndex(block => block.block_id === action.payload.block_id);
                if (index !== -1) {
                    state.blocks[index] = action.payload;
                } else {
                    state.blocks.push(action.payload);
                }
                state.loading = false;
            })
            .addCase(fetchBlock.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Create Block
            .addCase(createBlock.pending, (state) => {
                state.loading = true;
            })
            .addCase(createBlock.fulfilled, (state, action) => {
                state.blocks.push(action.payload);
                state.loading = false;
            })
            .addCase(createBlock.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Update Block
            .addCase(updateBlock.pending, (state) => {
                state.loading = true;
            })
            .addCase(updateBlock.fulfilled, (state, action) => {
                const index = state.blocks.findIndex((block) => block.block_id === action.payload.block_id);
                if (index !== -1) {
                    state.blocks[index] = action.payload;
                }
                state.loading = false;
            })
            .addCase(updateBlock.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            // Delete Block
            .addCase(deleteBlock.pending, (state) => {
                state.loading = true;
            })
            .addCase(deleteBlock.fulfilled, (state, action) => {
                state.blocks = state.blocks.filter((block) => block.block_id !== action.payload);
                state.loading = false;
            })
            .addCase(deleteBlock.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default blocksSlice.reducer;
