import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Button from 'components/base/Button';
import { ButtonGroup } from "react-bootstrap";
import { fetchDevices, deleteDevice } from "../../../../redux/slices/devicesThunk";
// import { useHass } from './HassProvider';
import HassProvider from './HassProvider';
import HassGraph from "./types/HassGraph";

const Ems_epex = () => {
    const dispatch = useDispatch();
    const devices = useSelector((state: RootStateOrAny) => state.devices.devices);
    const devicesLoading = useSelector((state: RootStateOrAny) => state.devices.loading);
    const [epexData, setEpexData] = useState([]);

    const entities = useSelector((state: RootStateOrAny) => state.devices.entities);

    useEffect(() => {
        dispatch(fetchDevices());

        if (entities && entities['sensor.epex_spot_data_net_price']) {
            setEpexData(entities['sensor.epex_spot_data_net_price'].attributes.data);
        }
    }, [entities, dispatch]);

    const handleDelete = async (deviceId) => {
        try {
            dispatch(deleteDevice({ device_id: deviceId }));
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <div>
            <h2>EPEX Prijzen</h2>
            <br />

            <HassProvider>
                <HassGraph entityId="sensor.epex_spot_data_net_price" />
            </HassProvider>
        </div>
    );
};

export default Ems_epex;
